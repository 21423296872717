// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="promo__hero">
        <div v-if="guest" class="container py-1">
            <div class="promo__hero__text">
                <span class="h1">{{ $t('welcomeHero.guest.welcome') }}</span><br>
                <span class="h2"><b>{{ $t('welcomeHero.guest.free') }}</b>
                    {{ $t('welcomeHero.guest.service') }}
                    <b>{{ $t('welcomeHero.guest.sharing') }}</b>
                </span>
                <br>
                <span>{{ $t('welcomeHero.guest.join') }}</span><br>
                <button class="btn btn-primary mt-05" @click="showAuthDialog">
                    {{ $t('welcomeHero.guest.start') }}
                </button>
            </div>
        </div>

        <div v-else class="container py-1">
            <div>
                <span>{{ $t('welcomeHero.user.welcome') }} {{ user.name }}</span><br>
                <b>{{ $t('welcomeHero.user.writings') }}
                    <nuxt-link class="accented-link" to="/publish/post">
                        {{ $t('welcomeHero.user.start') }}
                    </nuxt-link>
                </b>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        computed: {
            ...mapState('auth', ['user', 'guest']),
        },

        methods: {
            ...mapActions('auth', ['showAuthDialog']),
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .promo__hero {
        background: $light-blue;
        font-size: 1.2rem;
        min-height: 168px;

        &__text {
            text-align: center;
        }
    }
</style>
