// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="d-flex">
        <span
            v-for="(stat, index) in stats"
            :key="index"
            v-tooltip
            :title="stat.name"
            class="stats-item-suggestion text-muted d-flex mr-2"
        >
            <IconComponent :icon="stat.icon" />
            <div class="stat-value-suggestion">{{ value(stat.value) }}</div>
        </span>
    </div>
</template>

<script>
    import formatNumber from '~/filters/formatNumber'
    import IconComponent from '../IconComponent.vue'

    export default {

        components: {
            IconComponent,
        },
        props: {
            stats: {
                type: Array,
                required: true,
            },
        },

        methods: {
            value(value) {
                return value > 1 ? formatNumber(value) : value
            },
        },
    }

</script>

<style lang="css">
    .stats-item-suggestion {
        cursor: default;
        margin-right: 10px;
        margin-top: 10px;
        align-items: center;
    }
    .stat-value-suggestion {
        margin-left: 5px;
        margin-top: 3px;
    }
</style>
