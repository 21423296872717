// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div
        class="user-list-container"
        :class="{'d-flex justify-content-between flex-wrap': isSuggestion}"
    >
        <UserContainer v-for="id in userIds" :key="id" :user-id="id">
            <template slot-scope="scope">
                <slot v-bind="scope" />
            </template>
        </UserContainer>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _concat from 'lodash/concat'
    import { mapState } from 'vuex'
    import UserContainer from '~/containers/user/UserItem.vue'

    export default {
        components: {
            UserContainer,
        },

        props: {
            store: {
                type: String,
                required: true,
            },
            storeKey: String,
            isSuggestion: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState({
                userIds(state) {
                    const storePath = this.store.split('/')
                    const keyPath = this.storeKey ? this.storeKey.split('.') : []

                    return _get(state, _concat(storePath, keyPath))
                },
            }),
        },
    }
</script>
