// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="main-feed__sidebar">
        <template v-if="$fetchState.pending && !showQuestions">
            <el-skeleton animated>
                <template slot="template">
                    <div style="width:70%">
                        <el-skeleton-item variant="h3" />
                    </div>
                    <div v-for="i in 4" :key="`q-${i}`" class="mt-05 mb-1">
                        <el-skeleton-item variant="text" />
                        <el-skeleton-item variant="text" />
                        <el-skeleton-item variant="text" class="mt-05" style="width:70%" />
                        <el-skeleton-item variant="text" class="mt-05" style="width:55%" />
                    </div>
                </template>
            </el-skeleton>
        </template>

        <template v-else-if="showQuestions">
            <div class="main-feed__sidebar__questions-feed">
                <SectionTitle :title="$t('sidebar.question')" link="/questions" />

                <FeedItem
                    v-for="question in sidebar.questions"
                    :key="question.hash_id"
                    :title="question.title"
                    :path="toQuestion(question)"
                    :user="question.user.data"
                >
                    <Stats :stats="questionStats(question)" class="text-nowrap">
                        <Score
                            slot="append"
                            :score="question.points"
                            :title="$t('question.score')"
                            class="stats-item text-nowrap"
                        />
                    </Stats>
                </FeedItem>
            </div>
        </template>

        <div>
            <div
                v-if="!$fetchState.pending"
                class="mt-2 mb-2"
                @mouseenter="stopSwip()"
                @mouseleave="startSwip()"
            >
                <swiper
                    ref="swiperRef"
                    class="swiper elated-posts-box slide-show"
                    :options="swiperOption"
                >
                    <swiper-slide
                        v-for="suggestion in suggestions"
                        :key="suggestion"
                        class="item"
                    >
                        <div v-if="suggestion === 'code'">
                            <div
                                v-if="challenges.length > 0"
                                class="pr-1"
                            >
                                <SuggestionTilte
                                    :title="$t('sidebar.suggestion.challenge.title')"
                                    :icon="`code`"
                                    medium="HomePage Viblo"
                                />
                                <SuggestionList>
                                    <ChallengeItem
                                        v-for="challenge in challenges"
                                        :key="challenge.hash_id"
                                        :challenge="challenge"
                                        medium="HomePage Viblo"
                                    />
                                </SuggestionList>
                            </div>
                        </div>
                        <div v-if="suggestion === 'ctf'">
                            <div
                                v-if="puzzles.length > 0"
                                class="pr-1"
                            >
                                <SuggestionTilte
                                    :title="$t('sidebar.suggestion.puzzle.title')"
                                    :icon="`ctf`"
                                    medium="HomePage Viblo"
                                />
                                <SuggestionList>
                                    <PuzzlesItem
                                        v-for="puzzle in puzzles"
                                        :key="puzzle.hash_id"
                                        :puzzles="puzzle"
                                        medium="HomePage Viblo"
                                    />
                                </SuggestionList>
                            </div>
                        </div>
                        <div v-if="suggestion === 'learn'">
                            <div
                                v-if="courses.length > 0"
                                class="pr-1"
                            >
                                <SuggestionTilte
                                    :title="$t('sidebar.suggestion.course.title')"
                                    :icon="`learn`"
                                    medium="HomePage Viblo"
                                />
                                <SuggestionList>
                                    <CourseItem
                                        v-for="course in courses"
                                        :key="course.hash_id"
                                        :course="course"
                                        medium="Homepage Viblo"
                                    />
                                </SuggestionList>
                            </div>
                        </div>
                    </swiper-slide>
                    <div slot="pagination" class="swiper-pagination" />
                </swiper>
            </div>
            <SuggestionSkeleton v-else />
        </div>

        <template v-if="showTopAuthors">
            <div class="main-feed__sidebar__top-authors mt-2">
                <SectionTitle :title="$t('sidebar.author')" link="/authors" />

                <UserList store="postHomeFeed/sidebar/users">
                    <UserCard
                        slot-scope="item"
                        class="mb-1"
                        :user="item.user"
                        :follow="item.userAction('follow')"
                        :stats="['reputation', 'posts', 'followers', 'postviews']"
                    />
                </UserList>
            </div>
        </template>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { mapState, mapGetters, mapActions } from 'vuex'
    import { question as toQuestion } from '~/utils/url'
    import FeedItem from '~/components/sidebar/FeedItem.vue'
    import Stats from '~/components/shared/Stats.vue'
    import SectionTitle from '~/components/shared/SectionTitle.vue'
    import Score from '~/components/widgets/Score.vue'
    import SuggestionList from '~/components/sidebar/Suggestion/SuggestionList.vue'
    import SuggestionTilte from '~/components/sidebar/Suggestion/SuggestionTilte.vue'
    import ChallengeItem from '~/components/sidebar/Suggestion/CodeChallenge/ChallengeItem.vue'
    import CourseItem from '~/components/sidebar/Suggestion/LearnCourse/CourseItem.vue'
    import SuggestionSkeleton from '~/components/sidebar/Suggestion/SuggestionSkeleton.vue'
    import PuzzlesItem from '~/components/sidebar/Suggestion/CTF/PuzzlesItem.vue'
    import UserList from '~/containers/user/UserList.vue'
    import UserCard from '~/components/sidebar/UserSidebarCard.vue'

    export default {
        components: {
            Stats,
            FeedItem,
            SectionTitle,
            Score,
            SuggestionList,
            SuggestionTilte,
            ChallengeItem,
            CourseItem,
            SuggestionSkeleton,
            PuzzlesItem,
            UserList,
            UserCard,
        },

        data() {
            return {
                dataUsers: [],
                dataOrgs: [],
                dataUsersAfterUpdate: [],
                dataOrgsAfterUpdate: [],
                swiperOption: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    slidesPerGroup: 1,
                    loopFillGroupWithBlank: true,
                    loop: true,
                    autoplay: {
                        delay: 2500,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                },
                swiper: null,
            }
        },

        computed: {
            ...mapState('postHomeFeed', ['sidebar']),
            ...mapGetters('auth', ['authenticated']),
            ...mapGetters('postHomeFeed', ['getUserPartners']),

            showQuestions() {
                return _get(this.sidebar, 'questions', []).length > 0
            },

            showTopAuthors() {
                return _get(this.sidebar, 'users', []).length > 0
            },

            challenges() {
                return _get(this.sidebar, 'suggestionChallenges', [])
            },

            courses() {
                return _get(this.sidebar, 'suggestionCourses', [])
            },

            puzzles() {
                return _get(this.sidebar, 'suggestionPuzzles', [])
            },

            suggestions() {
                const arr = []
                if (_get(this.sidebar, 'suggestionChallenges', []).length > 0) {
                    arr.push('code')
                }

                if (_get(this.sidebar, 'suggestionPuzzles', []).length > 0) {
                    arr.push('ctf')
                }

                if (_get(this.sidebar, 'suggestionCourses', []).length > 0) {
                    arr.push('learn')
                }

                return arr
            },
        },

        fetchOnServer() {
            return !this.authenticated
        },

        watch: {
            dataUsers(value) {
                this.dataUsersAfterUpdate = value
            },

            dataOrgs(value) {
                this.dataOrgsAfterUpdate = value
            },
        },

        async fetch() {
            await this.$store.dispatch('postHomeFeed/fetchSidebarRecommend')
        },

        methods: {
            toQuestion,

            stopSwip() {
                if (!this.swiper) {
                    const { swiperRef } = this.$refs
                    if (swiperRef && typeof swiperRef.swiperInstance !== 'undefined') {
                        this.swiper = swiperRef.swiperInstance
                    }
                }

                return typeof this.swiper.destroyed === 'undefined' ? this.swiper.autoplay.stop() : null
            },

            startSwip() {
                if (!this.swiper) {
                    const { swiperRef } = this.$refs
                    if (swiperRef && typeof swiperRef.swiperInstance !== 'undefined') {
                        this.swiper = swiperRef.swiperInstance
                    }
                }

                return typeof this.swiper.destroyed === 'undefined' ? this.swiper.autoplay.start() : null
            },

            questionStats(question) {
                return [
                    {
                        name: this.$t('userInline.answers').concat(`${question.answers_count}`),
                        icon: question.solved ? 'fa fa-check text-success' : 'fa fa-reply',
                        value: question.answers_count,
                    },
                    {
                        name: this.$t('userInline.views').concat(`${question.views_count}`),
                        icon: 'fa fa-eye',
                        value: question.views_count,
                    },
                    {
                        name: this.$t('userInline.comments').concat(`${question.comments_count}`),
                        icon: 'fa fa-comment',
                        value: question.comments_count,
                    },
                ]
            },

            ...mapActions('entities/users', ['follow']),
        },
    }
</script>
