// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="container">
        <div class="pt-3 pb-1">
            <div
                class="d-flex align-items-baseline align-items-xs-between flex-xs-column flex-sm-row header-author-page"
            >
                <div class="section-title-line full-width">
                    <h4 class="text-uppercase">
                        {{ $t('topAuthors.title') }}
                    </h4>
                    <hr class="mx-1 filler-line">
                </div>
                <div class="d-flex align-items-baseline align-items-xs-between flex-shrink-0">
                    <span class="flex-shrink-0 mr-1">{{ $t('topAuthors.sortBy') }}</span>
                    <el-select
                        :value="sort"
                        class="form-control"
                        size="medium"
                        @change="handleSorting"
                    >
                        <el-option
                            v-for="(option, key, index) in sort_options"
                            :key="index"
                            :label="$t(option)"
                            :value="key"
                        />
                    </el-select>
                </div>
            </div>
            <div v-if="sort == 'reputation'" class="text-md-right mt-05">
                <nuxt-link
                    :to="toFilter('week')"
                    class="ml-05 link"
                    active-class="accented-link"
                    exact
                >
                    {{ $t('topAuthors.rangeFilter.week') }}
                </nuxt-link>
                <nuxt-link
                    :to="toFilter('month')"
                    class="ml-05 link"
                    active-class="accented-link"
                    exact
                >
                    {{ $t('topAuthors.rangeFilter.month') }}
                </nuxt-link>
                <nuxt-link
                    :to="toFilter('all')"
                    :class="{ 'accented-link': sort === 'reputation' && (!filter || filter ==='all') }"
                    class="ml-05 link"
                >
                    {{ $t('topAuthors.rangeFilter.all') }}
                </nuxt-link>
            </div>

            <empty v-if="users.length === 0">
                <p> {{ $t('empty') }} </p>
            </empty>

            <div v-else class="block-exhibition row mt-3">
                <div v-for="(userId, index) in users" :key="index" class="col-sm-6 col-md-4 avatar-min-md">
                    <user-ranking-item :user-id="userId" :show-reputation-only="showReputationOnly" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import { defaultTitle } from '~/plugins/seo'
    import { handlePageError } from '~/utils/pages'
    import Empty from '~/components/shared/Empty.vue'
    import UserRankingItem from '~/components/users/UserRankingItem.vue'

    export default {
        name: 'UserRanking',

        head() {
            return {
                title: this.withUnreadCount(`Authors - ${defaultTitle}`),
            }
        },

        components: {
            Empty,
            UserRankingItem,
        },

        data() {
            return {
                sort_options: {
                    reputation: 'topAuthors.sortOptions.reputation',
                    followers: 'topAuthors.sortOptions.followers',
                    posts: 'topAuthors.sortOptions.posts',
                },
            }
        },

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapState('userRankings', {
                users: 'current',
            }),

            sort() {
                return this.$route.query.sort || 'reputation'
            },

            filter() {
                return this.$route.query.filter || 'all'
            },

            showReputationOnly() {
                return this.sort === 'reputation' && this.filter !== 'all'
            },
        },

        fetch: ({ store, query, error }) => store.dispatch('userRankings/fetch', query)
            .catch(handlePageError(error)),

        watchQuery: ['sort', 'filter'],

        analytics: {
            title: 'User rankings',
            dimension1: 'profile.index',
        },

        methods: {
            handleSorting(value) {
                this.$router.push(this.toSort(value))
            },

            toFilter: filter => ({
                path: '/authors',
                query: {
                    sort: 'reputation',
                    filter,
                },
            }),

            toSort: sort => ({
                path: '/authors',
                query: { sort },
            }),
        },
    }
</script>

<style>
    .avatar-min-md .avatar > img {
        min-width: 3.750rem;
    }

    @media only screen and (max-width: 460px){
        .header-author-page {
            display: flex;
            flex-direction: column;
        }
    }
</style>
