export default [
    {
        param: '',
        text: 'contentCreator.rank.title',
    },
    {
        param: 'diamond',
        text: 'organization.level.diamond',
    },
    {
        param: 'gold',
        text: 'organization.level.gold',
    },
    {
        param: 'silver',
        text: 'organization.level.silver',
    },
]
