// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div id="comment-section" class="container post-section mb-2">
        <h3 v-if="!hasNoComment()" class="post-section-title cross-line my-2">
            <strong>{{ $t('discussions.title') }}</strong>
        </h3>

        <div v-if="hasNoComment()" class="card card-block text-sm-center mt-2">
            <p class="card-text text-muted">
                <i class="fa fa-comment-o" aria-hidden="true" /> {{ $t('discussions.noDiscussions') }}
            </p>
        </div>

        <comment
            v-for="(comment, index) in comments"
            :key="index"
            :comment="comment"
            :actions="actions"
        />

        <pagination
            v-if="pagination"
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
            :path="$route.path"
        />
    </div>
</template>

<script>
    import isEmpty from 'lodash/isEmpty'
    import axios from 'viblo-sdk/libs/axios'
    import { mapGetters, mapActions } from 'vuex'
    import Comment from './Item.vue'
    import Pagination from '../Pagination.vue'

    export default {
        components: {
            Comment,
            Pagination,
        },

        props: {
            comments: {
                type: Array,
            },
            pagination: {
                type: Object,
            },
        },

        provide() {
            return { actions: this.actions }
        },

        computed: {
            ...mapGetters('auth', ['authenticated']),
            actions() {
                return {
                    edit: this.edit,
                    reply: this.reply,
                    destroy: this.destroy,
                    vote: this.vote,
                }
            },
        },

        methods: {
            ...mapActions('commentsList', ['rated']),
            vote(value, comment) {
                const values = { up: 1, down: -1, none: 0 }
                const score = values[value] !== comment.rated_value ? value : 'none'

                return axios.post(`/comments/${comment.hash_id}/rate`, { score })
                    .then(({ data }) => {
                        this.rated({ comment, rated_value: data.rated_value })
                    })
            },
            hasNoComment() {
                return isEmpty(this.comments)
            },
        },
    }
</script>

<style lang="css">
    @keyframes flash {
        50% {
            background-color: rgba(75, 205, 159, 0.25);
        }
    }

    .cross-line {
        overflow: hidden;
        position: relative;
    }

    .cross-line:after {
        content:"";
        position: absolute;
        border-bottom:1px solid #D6D6D7;
        display: inline;
        margin-left: 1em;
    }

    #comment-section .highlight {
        animation: flash 1s ease-out 1;
    }
</style>
