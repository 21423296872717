// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <PostFeedPlaceholder v-if="$fetchState.pending && isEmpty" />

    <PostFeed
        v-else
        :should-highlight="isNewPost"
        :pinned-item="pinnedItem"
        store="postHomeFeed"
    >
        <div
            v-if="showNewPosts"
            slot="before"
            class="load-new-posts p-05 m-0 text-center cursor-pointer"
            @click="loadNewPosts"
        >
            <i v-if="loadingNewPosts" class="el-icon-loading" />
            {{ $t('seeNewPosts.firstContent') }} {{ newPosts }} {{ $t('seeNewPosts.lastContent') }}
        </div>
    </PostFeed>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import { feedSEO } from '~/utils/seo'
    import frontPage from '~/pages/__mixins/frontPage'
    import dynamicFetching from '~/pages/__mixins/dynamicFetching'

    import PostFeed from '~/containers/posts/PostFeed.vue'
    import PostFeedPlaceholder from '~/containers/posts/PostFeedPlaceholder.vue'

    export default {
        name: 'NewestPosts',

        components: {
            PostFeed,
            PostFeedPlaceholder,
        },

        mixins: [
            frontPage('newest', { visitFeed: 'newest' }),
            dynamicFetching(),
        ],

        data: () => ({
            loadingNewPosts: false,
        }),

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapGetters('postHomeFeed', ['isEmpty']),
            ...mapGetters('auth', ['authenticated']),
            ...mapState('announcements', ['pinnedItem']),
            ...mapState('toast', ['newPosts']),

            showNewPosts() {
                const onFirstPage = !this.$route.query.page || this.$route.query.page <= 1

                return onFirstPage && this.newPosts > 0 && process.client
            },
        },

        fetch() {
            return this.withProgressBar(() => {
                if (process.client) {
                    this.$store.commit('toast/clearNewPost')
                }

                if (!this.pinnedItem.length > 0) {
                    this.$store.dispatch('announcements/getPinnedItem')
                }


                return Promise.all([
                    this.authenticated ? this.$store.dispatch('settings/getSettingUser') : null,
                    this.$store.dispatch('postHomeFeed/fetch', {
                        feed: 'newest',
                        params: {
                            ...this.$route.query,
                            limit: this.$store.state.settings.perPage,
                        },
                    }),
                ])
            })
        },

        watchQuery: ['page'],

        methods: {
            loadNewPosts() {
                this.$nuxt.$loading.start()
                this.loadingNewPosts = true
                this.$store.dispatch('postHomeFeed/fetch', { feed: 'newest' })
                    .then(() => {
                        this.$nuxt.$loading.finish()
                        this.loadingNewPosts = false
                        this.$store.commit('toast/clearNewPost')
                    })
            },

            isNewPost({ published_at: publishedAt, user_id: userId }) {
                if (!this.lastSeen || this.$store.getters['auth/isUser'](userId) || !publishedAt) return false
                return new Date(this.lastSeen) < new Date(publishedAt)
            },
        },

        head() {
            const seo = feedSEO('newest')
            let title = this.$t('headSeo.newest')
            title = this.$store.state.settings.homepage === 'default' ? title : seo.title

            return {
                ...seo,
                title: this.withUnreadCount(title),
            }
        },
    }
</script>

<style lang="scss">
    .load-new-posts {
        background-color: #eee;

        &:hover {
            background-color: #ddd;
        }
    }
</style>
