<script>
    import { mapState } from 'vuex'

    export default {
        computed: {
            ...mapState('auth', ['guest', 'user']),

            editProfileUrl() {
                return `${process.env.SSO_APP_URL}`
            },
        },

        mounted() {
            this.$nextTick(() => {
                if (this.guest || !!this.user.picture) return

                this.$confirm(
                    this.$t('dialogNoAvt.content'),
                    this.$t('dialogNoAvt.title'),
                    {
                        type: 'warning',
                        confirmButtonText: this.$t('dialogNoAvt.button'),
                        showClose: false,
                        showCancelButton: false,
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                    }
                ).then(() => {
                    window.location.href = this.editProfileUrl
                })
            })
        },

        render() {
            return null
        },
    }
</script>
