// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <user :user="user" :follow="follow">
        <div slot="stats" class="user-stats text-muted">
            <span v-if="showReputationOnly" v-tooltip title="Reputation">
                <i class="fa fa-star" aria-hidden="true" /> {{ user.reputation_earned | signed }}
            </span>

            <template v-else>
                <span v-tooltip :title="$t('profile.reputations')">
                    <i class="fa fa-star" aria-hidden="true" /> {{ user.reputation_earned }}
                </span>
                <span v-tooltip :title="$t('profile.followers')">
                    <i class="fa fa-user-plus ml-05" aria-hidden="true" /> {{ user.followers_count }}
                </span>
                <span v-tooltip :title="$t('profile.posts')">
                    <i class="fa fa-pencil ml-05" aria-hidden="true" /> {{ user.posts_count }}
                </span>
            </template>
        </div>
    </user>
</template>

<script>
    import _get from 'lodash/get'
    import { mapActions } from 'vuex'
    import User from './ProfileCard.vue'

    export default {
        components: {
            User,
        },

        props: {
            userId: Number,
            showReputationOnly: Boolean,
            isSelf: {
                type: Boolean,
                default: null,
            },
        },

        computed: {
            user() {
                const authUserId = _get(this.$store.state, 'auth.user.id')
                const user = this.$store.getters['entities/users/get'](this.userId)
                const rankings = _get(this.$store.state, `userRankings.byId.${this.userId}`)

                return {
                    ...user,
                    is_self: this.isSelf !== null ? this.isSelf : authUserId === user.id,
                    ...rankings,
                }
            },
        },

        methods: {
            ...mapActions('entities/users', ['follow']),
        },
    }
</script>
