// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="search-guideline">
        <div class="section-title-line mb-1">
            <h4 class="text-uppercase">
                {{ $t('search.guide.label') }}
            </h4>
            <hr class="filler-line">
        </div>
        <div>
            <div v-for="(syntax, index) in syntaxes" :key="index">
                <v-tag :url="`?q=${syntax.example}`">
                    {{ syntax.example }}
                </v-tag>
                <p class="text-muted">
                    {{ $t(`search.guide.${syntax.explain}`) }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    const syntaxes = [
        { example: 'title:Git', explain: 'title' },
        { example: 'body:Ruby', explain: 'body' },
        { example: 'code:function', explain: 'code' },
        { example: 'tag:Rails', explain: 'tag' },
        { example: 'user:name', explain: 'user' },
    ]

    export default {
        data: () => ({
            syntaxes,
        }),
    }
</script>
