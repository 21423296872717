// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _concat from 'lodash/concat'
import { absoluteUrl, routeToQuestion, routeToUser } from '~/lib/functions'
import {
    imageMetaTags,
    descriptionMetaTags,
    formatToDate,
} from './function'
import { defaultName } from './defaultValue'
import { image as imageUrl } from '~/utils/url'

const structuredData = (question, answers) => {
    const contents = question.seo.description
    const suggestedAnswer = []
    const acceptedAnswer = []
    answers.forEach((answer) => {
        if (!answer.accepted) {
            suggestedAnswer.push({
                '@type': 'Answer',
                text: answer.contents,
                dateCreated: formatToDate(answer.created_at),
                upvoteCount: answer.points,
                url: absoluteUrl(routeToQuestion(question)),
                author: {
                    '@type': 'Person',
                    name: answer.user.data.name,
                    url: absoluteUrl(routeToUser(answer.user.data)),
                    image: imageUrl(answer.user.data.avatar),
                },
            })
        } else {
            acceptedAnswer.push({
                '@type': 'Answer',
                text: answer.contents,
                dateCreated: formatToDate(answer.created_at),
                upvoteCount: answer.points,
                url: absoluteUrl(routeToQuestion(question)),
                author: {
                    '@type': 'Person',
                    name: answer.user.data.name,
                    url: absoluteUrl(routeToUser(answer.user.data)),
                    image: imageUrl(answer.user.data.avatar),
                },
            })
        }
    })

    return {
        '@context': 'https://schema.org',
        '@type': 'QAPage',
        mainEntity: {
            '@type': 'Question',
            name: question.title,
            text: contents,
            answerCount: question.answers_count,
            upvoteCount: question.points,
            dateCreated: formatToDate(question.created_at),
            author: {
                '@type': 'Person',
                name: question.user.data.name,
                url: absoluteUrl(routeToUser(question.user.data)),
                image: imageUrl(question.user.data.avatar),
            },
            acceptedAnswer,
            suggestedAnswer,
        },
    }
}

const questionSEO = (question, answers) => {
    const keywords = question.tags.data.map(tag => tag.name).join(', ')
    const description = question.seo.description
    const image = question.seo.image

    const baseMeta = [
        { hid: 'og:type', property: 'og:type', content: 'article' },
        { hid: 'og:url', property: 'og:url', content: absoluteUrl(routeToQuestion(question)) },
        { hid: 'og:site_name', property: 'og:site_name', content: defaultName },
        { hid: 'og:title', property: 'og:title', content: question.title },
        { hid: 'keywords', name: 'keywords', content: keywords },
        {
            hid: 'article:published_time',
            name: 'article:published_time',
            content: question.seo.published_time,
        },
        {
            hid: 'article:modified_time',
            name: 'article:modified_time',
            content: question.seo.modified_time,
        },
        {
            hid: 'article:author',
            name: 'article:author',
            content: absoluteUrl(routeToUser(question.user.data)),
        },
        { hid: 'article:tag', name: 'article:tag', content: keywords },
        // twitter
        { hid: 'twitter:title', name: 'twitter:title', content: question.title },
        {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: description,
        },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
    ]

    return {
        title: `${question.title} - Viblo`,
        link: [
            { hid: 'canonical', rel: 'canonical', href: absoluteUrl(routeToQuestion(question)) },
        ],
        meta: _concat(baseMeta, imageMetaTags(image), descriptionMetaTags(description)),
        script: [
            { type: 'application/ld+json', json: structuredData(question, answers) },
        ],
    }
}

export { questionSEO }
