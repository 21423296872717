// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _isPlainObject from 'lodash/isPlainObject'
import _concat from 'lodash/concat'
import _truncate from 'lodash/truncate'
import _isEmpty from 'lodash/isEmpty'
import { defaultSEOImage } from './defaultValue'

/**
 * Format datetime to ISO 8601
 * @param {datetime} date
 */
const formatToDate = date => new Date(date).toISOString()

/**
 * Shorten title to a string with not exceed 110 characters.
 * @param {string} headLine
 */
const shortenHeadline = headLine => _truncate(headLine, {
    length: 110,
})

const imageMetaTags = (image) => {
    if (typeof image === 'string') {
        return { hid: 'og:image', property: 'og:image', content: image }
    }

    if (_isPlainObject(image)) {
        const { url, width, height } = image

        return [
            { hid: 'og:image', property: 'og:image', content: url },
            { hid: 'og:image:width', property: 'og:image:width', content: width },
            { hid: 'og:image:height', property: 'og:image:height', content: height },
        ]
    }

    return []
}

const descriptionMetaTags = description => [
    { hid: 'description', name: 'description', content: description },
    { hid: 'og:description', property: 'og:description', content: description },
]

const sharing = ({ title, description, image }) => {
    const base = [
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'twitter:title', name: 'twitter:title', content: title },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
    ]

    return _concat(base, descriptionMetaTags(description), imageMetaTags(image))
}

const extractImageFromContent = (content) => {
    const imagesArray = content.match(/(https?:\/\/.*\.(?:png|jpg))/i)

    return _isEmpty(imagesArray) ? defaultSEOImage : imagesArray
}

export {
    descriptionMetaTags,
    imageMetaTags,
    formatToDate,
    sharing,
    extractImageFromContent,
    shortenHeadline,
}
