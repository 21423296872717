// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _concat from 'lodash/concat'
import _isEmpty from 'lodash/isEmpty'
import { routeToPost, absoluteUrl, routeToUser } from '~/lib/functions'
import { baseUrl, image as imageUrl } from '~/utils/url'
import { descriptionMetaTags, imageMetaTags, formatToDate } from './function'
import { defaultName, defaultSEOImage, defaultSocialUrls } from './defaultValue'

const structuredData = (video) => {
    const contents = video.preview.contents

    const organizationName = _isEmpty(video.organization)
        ? video.organization.data.name
        : defaultName

    const organizationLogo = _isEmpty(video.organization)
        ? imageUrl(video.organization.data.avatar)
        : defaultSEOImage

    return {
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        name: video.title,
        description: contents,
        thumbnailUrl: video.preview.image,
        keywords: _isEmpty(video.tags) ? null : video.tags.data.map(tag => tag.name).join(', '),
        author: {
            '@type': 'Person',
            name: video.user.data.name,
            '@id': absoluteUrl(routeToUser(video.user.data)),
            url: absoluteUrl(routeToUser(video.user.data)),
        },
        publisher: {
            '@type': 'Organization',
            name: organizationName,
            logo: {
                '@type': 'ImageObject',
                url: organizationLogo,
            },
            sameAs: defaultSocialUrls,
        },
        url: absoluteUrl(routeToPost(video)),
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `${baseUrl}/videos`,
        },
        datePublished: formatToDate(video.published_at),
        uploadDate: formatToDate(video.created_at),
        interactionCount: video.view,
    }
}

const videoSEO = (video) => {
    const keywords = _isEmpty(video.tags) ? null : video.tags.data.map(tag => tag.name).join(', ')
    const description = video.preview.teaser
    const image = video.preview.image

    const baseMeta = [
        { hid: 'og:type', property: 'og:type', content: 'article' },
        { hid: 'og:url', property: 'og:url', content: absoluteUrl(routeToPost(video)) },
        { hid: 'og:site_name', property: 'og:site_name', content: defaultName },
        { hid: 'og:title', property: 'og:title', content: video.title },
        { hid: 'keywords', name: 'keywords', content: keywords },
        {
            hid: 'article:published_time',
            name: 'article:published_time',
            content: video.published_at,
        },
        {
            hid: 'article:modified_time',
            name: 'article:modified_time',
            content: video.updated_at,
        },
        {
            hid: 'article:author',
            name: 'article:author',
            content: absoluteUrl(routeToUser(video.user.data)),
        },
        { hid: 'article:tag', name: 'article:tag', content: keywords },
        // twitter
        { hid: 'twitter:title', name: 'twitter:title', content: video.title },
        {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: description,
        },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
    ]

    return {
        title: `${video.title} - Viblo`,
        link: [{ hid: 'canonical', rel: 'canonical', href: absoluteUrl(routeToPost(video)) }],
        meta: _concat(baseMeta, imageMetaTags(image), descriptionMetaTags(description)),
        script: [{ type: 'application/ld+json', json: structuredData(video) }],
    }
}

export { videoSEO }
