// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="sidebar__feed-item suggestion-sidebar">
        <slot />
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .sidebar__feed-item {
        padding-bottom: .5rem;
        margin-bottom: .5rem;
        border-bottom: 1px solid $gray-200;

        &__info{
            text-align: center;
            display: inline-block;
            div{
                float: left;
            }
        }

        &:last-of-type{
            border-bottom:none;
        }

        &__subtitle{
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .suggestion-sidebar{
        .suggestion-item-content {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            font-size: 1rem;
            color:black;

            &:hover {
                text-decoration: none;
                color: #5488c7;
            }
        }

        .suggestion-item-tags {
            padding: 1px 10px;
            background-color: rgb(244, 244, 245);
            color: rgb(144, 147, 153) !important;
            border-radius: 5px;
            max-width: 230px;
            margin-right:5px;

            &:hover {
                text-decoration: none !important;
                background-color: #eaeaea;
                color: #909399;
            }
        }

        .truncate-container {
            display: table-cell;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 250px;
        }

        .truncate-container a {
            margin-right: 5px;
            box-sizing: border-box;
            display: inline-block;
            white-space: nowrap;
            font-size: small;
        }

        .time-published {
            margin-bottom: 5px;
            font-size: small;
        }
    }
</style>
