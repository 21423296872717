// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <MainBanner />

        <RecommendAuthors :users="relatedAuthors" />

        <RecommendTags :tags="relatedTags" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { handlePageError } from '~/utils/pages'
    import { getRelatedTags, getRelatedAuthors } from '~/api/placements'
    import { normalizeTagList, normalizeUserList } from '~/components/entities/normalize'

    import MainBanner from '~/components/frontPage/MainBanner.vue'
    import RecommendAuthors from '~/components/recommend/RecommendAuthors.vue'
    import RecommendTags from '~/components/recommend/RecommendTags.vue'

    export default {
        components: {
            MainBanner,
            RecommendAuthors,
            RecommendTags,
        },

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapGetters('auth', ['authenticated']),

            relatedTags() {
                return this.$store.getters['entities/tags/mapId'](this.tagIds)
            },

            relatedAuthors() {
                return this.$store.getters['entities/users/mapId'](this.authorIds)
            },
        },

        async asyncData({
            store, error,
        }) {
            try {
                const relatedTags = await getRelatedTags()
                    .then(response => ({
                        relatedTags: response.data.data,
                    }))
                const relatedAuthors = await getRelatedAuthors()
                    .then(response => ({
                        relatedAuthors: response.data.data,
                    }))

                const normalizedTags = normalizeTagList(relatedTags.relatedTags)
                const normalizedAuthors = normalizeUserList(relatedAuthors.relatedAuthors)

                const tagIds = normalizedTags.result
                const authorIds = normalizedAuthors.result

                store.commit('entities/tags/put', normalizedTags.entities.tags)
                store.commit('entities/users/put', normalizedAuthors.entities.users)

                return { tagIds, authorIds }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        fetch({ store }) {
            return Promise.all([
                store.dispatch('config/changeBanner'),
            ])
        },

        middleware: ['auth'],

    }
</script>
