// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="item d-flex pt-05 pb-05">
        <div class="right-col">
            <div class="comment content-limit">
                <div class="body-discussion">
                    <div class="discussion-item">
                        <Avatar
                            :image="user.avatar"
                            :username="user.username"
                            size="sm"
                            class="mr-1"
                        />
                        <UserInline
                            :user="user"
                            :show-avatar="false"
                            :show-username="false"
                            class="mr-1 title-discussion-user-inline item-discussion"
                        />

                        <div class="mr-1">
                            {{ actionName }}
                        </div>

                        <div class="title-and-avt">
                            <nuxt-link :to="routeToComment" class="mr-1 item-discussion discussion-item-title">
                                {{ commentable.title }}
                            </nuxt-link>

                            <span v-if="!own_entity" class="mr-1">
                                {{ $t('discussions.of') }}
                            </span>
                            <Avatar
                                :image="comment.commentable.data.user.data.avatar"
                                :username="commentable.user.data.username"
                                size="sm"
                                class="mr-1"
                            />
                            <UserInline
                                class="title-discussion-user-inline"
                                :user="comment.commentable.data.user.data"
                                :show-avatar="false"
                                :show-username="false"
                            />
                        </div>
                    </div>
                    <span v-tooltip class="text-muted time-cmt time-comment" :title="comment.created_at | formatTime">
                        <i class="fa fa-calendar mr-05" /> {{ comment.created_at | humanizeTime }}
                    </span>
                </div>

                <ContentBox class="mb-05">
                    <Markdown :contents="comment.contents" />
                </ContentBox>

                <footer class="d-flex text-muted">
                    <comment-vote
                        :send="voteComment"
                        :score="comment.points"
                        :value="comment.rated_value"
                        :disabled="is_own || !canRateComment"
                        :is-own="is_own"
                        :rateable="{ type: 'Comment', id: comment.id }"
                    />
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import { routeToUser as toUser } from '~/lib/functions'

    import Avatar from '../shared/user/Avatar.vue'
    import CommentVote from '../comments/Vote.vue'
    import Markdown from '~/components/contents/MarkdownPure.vue'
    import ContentBox from '~/components/contents/ContentBox.vue'
    import UserInline from '~/components/shared/user/Inline.vue'

    export default {
        components: {
            Avatar,
            CommentVote,
            Markdown,
            ContentBox,
            UserInline,
        },

        props: {
            comment: Object,
            actions: {
                type: Object,
                default: () => ({}),
            },
        },

        data: () => ({
            formOpen: false,
        }),

        computed: {
            ...mapState('auth', {
                is_own(state) {
                    return state.user && state.user.id === this.comment.user_id
                },
                own_entity(state) {
                    return state.user && state.user.id === this.comment.commentable.data.user.data.id
                },
            }),
            ...mapGetters('auth', [
                'canRateComment', 'authenticated',
            ]),

            commentable() {
                return this.comment.commentable.data
            },

            actionName() {
                switch (this.comment.commentable_type) {
                    case 'Answer':
                        if (this.own_entity) return this.$t('discussions.answer.isOwner')
                        return this.$t('discussions.answer.default')
                    case 'Question':
                        if (this.own_entity) return this.$t('discussions.question.isOwner')
                        return this.$t('discussions.question.default')
                    case 'Post':
                        if (this.own_entity) return this.$t('discussions.post.isOwner')
                        return this.$t('discussions.post.default')
                    case 'Series':
                        if (this.own_entity) return this.$t('discussions.series.isOwner')
                        return this.$t('discussions.series.default')
                    default:
                        return this.$t('discussions.default')
                }
            },

            routeToComment() {
                return `${this.commentable.path}#comment-${this.comment.hash_id}`
            },

            user() {
                return this.$store.getters['entities/users/get'](this.comment.user_id)
            },
        },

        methods: {
            toUser,

            voteComment(value) {
                return this.actions.vote(value, this.comment)
            },

            update({ data }) {
                this.actions.update(this.comment.hash_id, data)
                    .then(() => {
                        this.formOpen = false
                    })
            },

            reply(username) {
                this.actions.reply(username)
            },

            destroy() {
                this.$confirm(
                    this.$t('detailQuestion.commentHasNotAccepted.confirmDelete.content'),
                    this.$t('detailQuestion.commentHasNotAccepted.confirmDelete.title'), {
                        confirmButtonText: this.$t('confirm.ok'),
                        cancelButtonText: this.$t('confirm.cancel'),
                    }
                )
                    .then(() => this.actions.destroy(this.comment.hash_id))
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/sass/bootstrap/colors";
    @import "../../assets/sass/bootstrap/mixins";
    .item {
        font-size: .85rem;
        padding: .5rem 0;
        border-bottom: 1px solid $gray-lighter;
    }
    .right-col {
        width: 100%;
    }
    .comment {
        font-size: 1.1em;

        border-top: none !important;
        border-bottom: none !important;
    }
    .sm-avatar{
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .content-limit .md-contents {
        /*max-height: 15rem !important;*/
        overflow-y: hidden;
    }

    @media only screen and (min-width: 1000px){
        .discussion-item-title {
            width: 13vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-grow: 1;
        }
    }

    @media only screen and (max-width: 1000px){
        .item-discussion {
            padding-bottom: 10px
        }
        .discussion-item-title {
            width: 40vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .time-cmt {
            display: none;
        }
        .title-discussion-user-inline {
            max-width: 15vw !important;
        }
    }

    .title-discussion-user-inline {
        max-width: 8vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block !important ;
    }

    .body-discussion {
        display: flex;
        justify-content: space-between;
    }

    .discussion-item {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
    }

    .time-comment {
        min-width: 10rem;
        margin-left: 2rem;
    }

    .title-and-avt {
        display: flex;
        flex-grow: 1;
    }
</style>
