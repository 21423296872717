import Vue from 'vue'
import { mapGetters } from 'vuex'
import _get from 'lodash/get'

const shouldShowProgressBar = () => !_get(Vue.prototype, '$_store.state.auth.user')

export default () => ({
    loading: shouldShowProgressBar(),

    computed: {
        ...mapGetters('auth', ['authenticated']),
    },

    watch: {
        '$route.query': '$fetch',
    },

    fetchOnServer() {
        return !this.authenticated
    },

    methods: {
        async withProgressBar(callback) {
            // Although 'loading: fale', progress bar still displays.
            // So we need to update progress bar mannually for better UX
            if (process.client && this.authenticated && !!this.$nuxt.$loading.start) {
                this.$nuxt.$loading.start().increase(5)
            }

            await callback()

            if (process.client && this.authenticated) {
                this.$nuxt.$loading.finish()
            }
        },
    },
})
