// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <MainBanner />
        <MessageOfTheDay />
        <NotificationBanner />

        <dicussion :comments="comments" :pagination="pagination" />
        <NoAvatarReminder />
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import { feedSEO } from '~/utils/seo'

    import MainBanner from '~/components/frontPage/MainBanner.vue'
    import Dicussion from '~/components/discussion/Main.vue'
    import NotificationBanner from '~/components/notifications/Banner.vue'
    import MessageOfTheDay from '~/components/frontPage/MessageOfTheDay.vue'
    import NoAvatarReminder from '~/components/dialogs/NoAvatarReminder.vue'

    export default {
        components: {
            MainBanner,
            Dicussion,
            NotificationBanner,
            MessageOfTheDay,
            NoAvatarReminder,
        },

        data() {
            return {
                centerDialogVisible: true,
            }
        },

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapState('commentsList', ['comments', 'pagination']),
            ...mapGetters('auth', ['authenticated']),
        },

        fetch({ store, query }) {
            return Promise.all([
                store.dispatch('config/changeBanner'),
                store.dispatch('commentsList/fetch', {
                    page: query.page,
                    limit: store.state.settings.perPage,
                }),
                store.dispatch('config/changeMotd'),
                store.dispatch('config/changeLogo'),
            ])
        },

        watchQuery: ['page'],

        beforeMount() {
            Promise.all([
                this.$store.dispatch('badges/fetch'),
                this.$store.dispatch('auth/fetchLastSeen'),
            ])
        },

        head() {
            const seo = feedSEO('discussion')

            return {
                ...seo,
                title: this.withUnreadCount(seo.title),
            }
        },
    }
</script>
