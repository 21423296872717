// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <FilterType class="filterType m-05" />

        <PostsDiamond
            v-if="showPostByLevel('diamond')"
            :show-more-diamond="showMoreDiamond"
            :show-post-by-level="showPostByLevel('diamond')"
            @showFullByLevel="showFull"
        />

        <PostsGold
            v-if="showPostByLevel('gold')"
            :show-more-gold="showMoreGold"
            :show-post-by-level="showPostByLevel('gold')"
            @showFullByLevel="showFull"
        />

        <PostsSilver
            v-if="showPostByLevel('silver')"
            :show-more-silver="showMoreSilver"
            :show-post-by-level="showPostByLevel('silver')"
            @showFullByLevel="showFull"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import FilterType from '~/components/searchPage/FilterPostsByLevel.vue'
    import { feedSEO } from '~/utils/seo'
    import PostsDiamond from '~/components/LevelDiamond.vue'
    import PostsGold from '~/components/LevelGold.vue'
    import PostsSilver from '~/components/LevelSilver.vue'
    import frontPage from '~/pages/__mixins/frontPage'

    export default {
        name: 'ContentCreatorPosts',

        components: {
            FilterType,
            PostsDiamond,
            PostsGold,
            PostsSilver,
        },

        mixins: [
            frontPage('content-creator', { visitFeed: 'content-creator' }),
        ],

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapState('postHomeFeed', ['postOrgLevelSilver', 'postOrgLevelGold', 'postOrgLevelDiamond', 'pagination']),

            showMoreDiamond() {
                return this.$route.query.level === 'diamond'
            },

            showMoreGold() {
                return this.$route.query.level === 'gold'
            },

            showMoreSilver() {
                return this.$route.query.level === 'silver'
            },

            emptyData() {
                const emptyData = this.postOrgLevelSilver === null
                    && this.postOrgLevelGold === null
                    && this.postOrgLevelDiamond === null

                return emptyData
            },
        },

        methods: {
            showPostByLevel(level) {
                return !this.$route.query.level || this.$route.query.level === level
            },

            showFull(level) {
                switch (level) {
                    case 'diamond':
                        return { showMoreDiamond: true }
                    case 'gold':
                        return { showMoreGold: true }
                    case 'silver':
                        return { showMoreSilver: true }
                    default:
                        return 0
                }
            },
        },

        head() {
            const head = feedSEO('content-creator')

            head.title = this.withUnreadCount(head.title)

            return head
        },
    }
</script>
