// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div id="comment-section" class="container post-section mb-2">
        <h3 class="post-section-title cross-line my-2">
            <strong>{{ $t('tagRecommend.title') }}</strong>
        </h3>

        <div v-if="tags.length === 0" class="text-center font-weight-bold text-muted my-05">
            <slot>{{ $t('tagRecommend.content') }}</slot>
        </div>

        <div v-else>
            <div class="row block-exhibition">
                <div v-for="(tag, index) in tags" :key="index" class="col-sm-6 col-lg-4 my-2 exhibition-item">
                    <tag-item :tag="tag" />
                </div>
            </div>

            <div class="text-center font-weight-bold mt-1 mb-3">
                <nuxt-link to="/explore/tags">
                    {{ $t('authorsRecommend.seeMore') }}
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
    import TagItem from '~/components/tags/Card.vue'

    export default {
        components: {
            TagItem,
        },

        props: {
            tags: {
                type: Array,
            },
        },
    }
</script>

<style lang="css">
    @keyframes flash {
        50% {
            background-color: rgba(75, 205, 159, 0.25);
        }
    }

    .cross-line {
        overflow: hidden;
        position: relative;
    }
</style>
