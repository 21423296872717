// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <MainBanner v-if="currentBanner" />
        <WelcomeHero v-else />

        <div class="bg-dark py-md-1 py-05">
            <div class="container">
                <div class="row d-flex flex-nowrap align-items-center justify-content-between">
                    <feed-bar :feeds="feeds" :is-may-fest="happeningMayfest" />

                    <nuxt-link
                        v-if="!guest"
                        to="/publish/post"
                        class="btn btn-primary ml-2 float-right text-uppercase hidden-md-down"
                    >
                        <i class="fa fa-pencil" aria-hidden="true" /> {{ $t('feedBar.createPost') }}
                    </nuxt-link>
                </div>
            </div>
        </div>
        <div v-if="shouldShowMessOfDay">
            <MessageOfTheDay />
        </div>
        <div
            v-else
            class="text-white text-center h3 font-weight-bold py-1"
            style="background:linear-gradient(to right, #8A3C52, #6A82FB);"
        >
            <div v-if="isUpcomingMayFest">
                <client-only>
                    <flip-countdown
                        :deadline="getStartTimeMayFest/1000"
                    />
                </client-only>
                <a
                    class="text-white mt-1 d-inline-block"
                    href="https://mayfest.viblo.asia/"
                    target="_blank"
                >
                    {{ $t('feedBar.mayfest.starts') }}
                </a>
            </div>
            <div v-else-if="isDelayEnd" class="text-white d-inline-block">
                {{ $t('feedBar.mayfest.end') }}
            </div>
            <div v-else>
                <client-only>
                    <flip-countdown
                        :deadline="getEndTimeMayFest/1000"
                    />
                </client-only>
                <a
                    class="text-white mt-1 d-inline-block"
                    href="https://mayfest.viblo.asia/"
                    target="_blank"
                >
                    {{ $t('feedBar.mayfest.happenning') }}
                </a>
            </div>
        </div>
        <div class="container">
            <div class="row pt-3 pb-1">
                <div class="col-lg-9 pl-md-0">
                    <el-alert
                        v-if="!guest && followingTags < 5"
                        type="warning"
                        class="mb-1"
                        title
                        show-icon
                    >
                        {{ $t('alAlert.followingTagsLessThanFive.title') }}
                        <a
                            class="link"
                            href="https://machine-learning.viblo.asia"
                            rel="noopener"
                            target="_blank"
                        >
                            {{ $t('alAlert.followingTagsLessThanFive.machineLearing') }}
                        </a>
                        {{ $t('alAlert.followingTagsLessThanFive.follow') }}
                        <strong>{{ $t('alAlert.followingTagsLessThanFive.tag') }}</strong>
                        {{ $t('alAlert.followingTagsLessThanFive.reading') }}
                        <el-button type="text">
                            <nuxt-link class="link" to="/get-started">
                                <i class="fa fa-hand-o-right" />
                                <strong>{{ $t('alAlert.followingTagsLessThanFive.interest') }}</strong>
                            </nuxt-link>
                        </el-button>
                    </el-alert>

                    <el-alert
                        v-if="termWarningVisible"
                        type="warning"
                        class="mb-1"
                        title
                        show-icon
                    >
                        {{ $t('alAlert.termWarningVisible.update') }}
                        <a
                            class="accented-link"
                            href="https://viblo.asia/terms"
                            rel="noopener"
                            target="_blank"
                            type="primary"
                        >
                            {{ $t('alAlert.termWarningVisible.termsOfService') }}
                        </a>
                        {{ $t('alAlert.termWarningVisible.detail') }}
                    </el-alert>

                    <nuxt-child />
                </div>
                <div class="col-lg-3 hidden-md-down px-0">
                    <StickySidebar>
                        <Sidebar />
                    </StickySidebar>
                </div>
            </div>
        </div>

        <NoAvatarReminder />
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import {
        differenceInDays, getYear,
    } from 'date-fns'
    import { zonedTimeToUtc } from 'date-fns-tz'
    import FlipCountdown from '~/components/FlipCountdown.vue'
    import MainBanner from '~/components/frontPage/MainBanner.vue'
    import WelcomeHero from '~/components/frontPage/WelcomeHero.vue'
    import MessageOfTheDay from '~/components/frontPage/MessageOfTheDay.vue'
    import StickySidebar from '~/components/StickySidebar.vue'
    import Sidebar from '~/containers/postHomeFeed/SidebarRecommend.vue'
    import FeedBar from '~/components/FeedBar/index.vue'
    import feedsDefault from '~/components/FeedBar/links/posts'
    import feedsSettings from '~/components/FeedBar/links/posts-settings'
    import NoAvatarReminder from '~/components/dialogs/NoAvatarReminder.vue'
    import * as sntp from '~/lib/sntp'

    const currentYear = getYear(sntp.now())

    const endTermWarning = zonedTimeToUtc('2020-10-10T00:00:00.000+07:00')
    const startMayFest = new Date(`${currentYear}-05-01T00:00:00.000+07:00`)
    const endMayFest = new Date(`${currentYear}-06-01T00:00:00.000+07:00`)
    const dateNow = new Date(sntp.now())

    const headUpTime = 2592000000
    const delayEndTime = 1209600000

    export default {
        name: 'PostHomeFeedPage',

        middleware: 'homepage-redirect',

        components: {
            MainBanner,
            WelcomeHero,
            MessageOfTheDay,
            StickySidebar,
            FeedBar,
            Sidebar,
            FlipCountdown,
            NoAvatarReminder,
        },

        scrollToTop: true,

        data() {
            return {
                centerDialogVisible: true,
                termWarningVisible: differenceInDays(endTermWarning, dateNow) > 0,
                suggestions: ['code', 'ctf', 'learn'],
            }
        },

        computed: {
            ...mapState('auth', ['guest']),
            ...mapState('settings', ['homepage']),
            ...mapGetters('config', ['currentBanner']),
            ...mapGetters('auth', ['authenticated']),

            getStartTimeMayFest() {
                return this.$store.state.mayFestTime.startTime
                    ? (new Date(this.$store.state.mayFestTime.startTime)).getTime()
                    : startMayFest.getTime()
            },

            getEndTimeMayFest() {
                return this.$store.state.mayFestTime.endTime
                    ? (new Date(this.$store.state.mayFestTime.endTime)).getTime()
                    : endMayFest.getTime()
            },

            isDelayEnd() {
                const diff = dateNow - this.getEndTimeMayFest

                return diff < delayEndTime && diff > 0
            },

            isUpcomingMayFest() {
                const diff = this.getStartTimeMayFest - dateNow

                return diff < headUpTime && diff >= 0
            },

            isMayFest() {
                return this.$store.state.mayFestTime.active
                    ? this.getEndTimeMayFest + delayEndTime - dateNow >= 0
                        && dateNow - this.getStartTimeMayFest + headUpTime > 0
                    : false
            },

            happeningMayfest() {
                return this.$store.state.mayFestTime.happening
            },

            shouldShowMessOfDay() {
                return !this.isMayFest
            },

            active() {
                return this.$route.path
            },

            path() {
                return this.$route.path
            },

            followingTags() {
                return this.$store.state.auth.user.following_technicals_count
            },

            feeds() {
                return this.homepage === 'default' ? feedsDefault : feedsSettings
            },
        },

        async fetch() {
            await Promise.all([
                this.authenticated ? this.$store.dispatch('settings/getSettingUser') : null,
                this.$store.dispatch('config/changeBanner'),
                this.$store.dispatch('config/changeMotd'),
                this.$store.dispatch('config/changeLogo'),
            ])
        },
    }
</script>
