// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="feed-bar">
        <div class="feedbar-wrapper">
            <ul class="feed-links">
                <nuxt-link
                    v-for="(link, index) in filterFeed"
                    :key="index"
                    :to="link.path"
                    :exact="true"
                    :class="{
                        'active': active(link),
                        'hidden': link.name === 'posts-may-fest' && !isMayFest,
                        'feed-item-not-event': !isMayFest
                    }"
                    tag="li"
                    class="feedbar-item"
                    @click.native="changeMessage"
                >
                    <a
                        v-if="isMayFest ? true : link.name != 'posts-may-fest'"
                        class="feed-link"
                        :class="link.name"
                        :href="link.path"
                    >
                        <el-badge
                            v-if="link.name == 'posts-may-fest'"
                            :is-dot="badges && !!badges[link.name]"
                            class="badge-has-new"
                        >
                            {{ $t(link.title) }}{{ currentYear }}
                        </el-badge>
                        <el-badge
                            v-else
                            :is-dot="badges && !!badges[link.name]"
                            class="badge-has-new"
                        >
                            {{ $t(link.title, {year: currentYear}) }}
                        </el-badge>
                    </a>
                </nuxt-link>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            feeds: Array,
            isMayFest: Boolean,
        },

        computed: {
            ...mapState('auth', ['guest']),
            ...mapState('badges', {
                badges: 'items',
            }),

            filterFeed() {
                return this.feeds.filter(link => !link.auth || !this.guest)
            },

            currentYear() {
                return (new Date()).getFullYear()
            },
        },

        methods: {
            active(feed) {
                return feed.path === this.$route.path || feed.alias === this.$route.path
            },

            changeMessage() {
                this.$store.dispatch('config/changeMotd')
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/mixins";
    @import "../../assets/sass/bootstrap/variables";

    .feed-bar {
        height: 1.6em;
        overflow-y: hidden;
        flex: 1;

        @include media-breakpoint-down(sm) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        ul.feed-links {
            list-style: none;
            padding-left: 0;
        }

        .feed-links {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            overflow-x: overlay;
            min-height: calc(#{$line-height-base}rem + 11px);
            padding-bottom: 0;
            margin-bottom: 0;
            overflow-y: hidden;
            margin-top: 0 !important;
            max-height: 35px;

            .hidden {
                display: none;
            }

            .feedbar-item {
                padding: 0;
                position: relative;
                margin: 0 0.8em;
                flex-shrink: 0;
                top: -5px;
                .badge-has-new .el-badge__content.is-dot {
                    right: -2px;
                    top: 3px;
                }

                .feed-link {
                    color: #FFF;
                    font-size: .9em;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-decoration: none;

                    .counter {
                        color: #FFF;
                        margin-left: 5px;
                        padding: 2px 6px;
                        background-color: $primary;
                        border-radius: $base-border-radius;
                    }

                    &.posts-may-fest {
                        .el-badge {
                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 4px;
                            background: linear-gradient(90deg, #B335EF, #2DC1F0);
                            font-family: 'Origin Tech Demo';
                            font-weight: 400;
                            line-height: 21px;
                            font-size: 16px;
                        }
                        .badge-has-new .el-badge__content.is-dot {
                            right: -4px;
                            top: 9px;
                        }
                    }
                }
            }

            .feed-item-not-event {
                margin-right: 1.5rem !important;
                margin-left: 1rem;
            }
        }

        /* Animation */
        .feedbar-item {
            .feed-link {
                &:after {
                    left: 0;
                    width: 0;
                    height: 2px;
                    content: '';
                    bottom: -8px;
                    display: block;
                    position: absolute;
                    background-color: #FFF;
                    transition: width .3s ease;
                }

                &:hover:after {
                    width: 100%;
                }
            }

            &.active > .feed-link {
                &:after {
                    width: 100%;
                    height: 2px;
                    content: '';
                    display: block;
                    margin-top: 5px;
                    background-color: #FFF;
                }
            }

            .posts-may-fest {
                &:after {
                    left: 0;
                    width: 0;
                    height: 2px;
                    content: '';
                    bottom: -8px;
                    display: block;
                    position: absolute;
                    background: linear-gradient(90deg, #B335EF, #2DC1F0);
                    -webkit-text-stroke: 0.3px #00ffff;
                    transition: width .3s ease;
                }

                &:hover:after {
                    width: 100%;
                }
            }

            &.active > .posts-may-fest {
                &:after {
                    width: 100%;
                    height: 2px;
                    content: '';
                    display: block;
                    margin-top: 3px;
                    background: linear-gradient(90deg, #B335EF, #2DC1F0);
                }
            }
        }
    }

    @include media-breakpoint-between(sm, md) {
        .feed-bar {
            & > .feed-links {
                width: 1000px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .feed-bar {
            min-height: 35px;

            .feed-links {
                margin-top: 3px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .feed-bar {
            height: #{$line-height-base}rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .feed-bar .feed-links .active {
            border-bottom: 2px solid #fff;
        }
    }

    @keyframes textShine {
        to {
            background-position: 200%;
        }
    }
</style>
