// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="sidebar-wrapper">
        <div ref="dummy" class="dummy" />
        <div ref="sidebar" :style="sidebarStyle" class="sticky-sidebar">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            offsetTop: {
                type: Number,
                default: 80,
            },
            offsetBottom: {
                type: Number,
                default: 43,
            },
            obstacle: {
                type: String,
                default: 'footer#footer',
            },
        },

        data() {
            return {
                sidebarStyle: {
                    top: `${this.offsetTop}px`,
                    maxHeight: `calc(100vh - ${this.offsetTop + 8}px)`,
                },
                observer: null,
            }
        },

        mounted() {
            if ('IntersectionObserver' in window) {
                this.observer = new IntersectionObserver(this.stucked, {
                    threshold: 1,
                    rootMargin: `-${this.offsetTop}px 0px 0px 0px`,
                })

                this.observer.observe(this.$refs.dummy)
            }
        },

        beforeDestroy() {
            if (this.observer) {
                this.observer.unobserve(this.$refs.dummy)
            }
        },

        methods: {
            stucked(entries) {
                const entry = entries[0]

                if (entry.isIntersecting === true) {
                    this.$set(this.sidebarStyle, 'overflowY', 'hidden')
                    this.$refs.sidebar.scrollTo(0, 0)
                } else {
                    this.$set(this.sidebarStyle, 'overflowY', 'auto')
                }
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    @import "~assets/sass/bootstrap/borders";

    .sticky-sidebar {
        position: sticky;
        overflow-y: hidden;
        overflow-x: hidden;
        padding-right: 0.5rem;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: #f0f0f0;
        }
    }

    .sidebar-wrapper {
        height: 100%;
        padding-bottom: 8px;
    }

    .dummy {
        height: 1px;
    }
</style>
