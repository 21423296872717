// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export default () => ({
    beforeRouteEnter(to, from, next) {
        next(vm => vm.fetchBadges())
    },

    beforeRouteUpdate(to, from, next) {
        this.fetchBadges()
        next()
    },

    methods: {
        fetchBadges() {
            return Promise.all([
                this.$store.dispatch('badges/fetch'),
                this.$store.dispatch('auth/fetchLastSeen'),
            ])
        },
    },
})
