// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="mt-1">
        <div v-on-clickaway="hideTooltip" class="header-challenge">
            <div
                class="left-box"
                :style="{
                    width: !puzzles.rank ? `100%` : `80%`
                }"
            >
                <a
                    :href="getUrl(`${puzzles.link}?`, medium)"
                    target="_blank"
                    :class="modeTheme === 'theme-dark' ? 'dark-mode-color' : 'suggestion-item-content'"
                >
                    {{ puzzles.title }}
                </a>
                <p class="time-published text-muted">
                    {{ puzzles.created_at | humanize-time }}
                </p>
                <el-popover
                    v-if="puzzles && puzzles.tags.length > 0"
                    v-model="visible"
                    trigger="manual"
                    placement="top-start"
                    width="200"
                >
                    <div
                        class="d-flex flex-wrap"
                        @mouseover="showToolTip(`${puzzles.id}-puzzles`)"
                        @mouseleave="hideTooltip"
                    >
                        <a
                            v-for="tag in puzzles.tags"
                            :key="tag.id"
                            class="suggestion-item-tags"
                            :href="getUrl(`${url}/puzzles?tag=${tag.slug}&`, medium)"
                            target="_blank"
                        >
                            {{ tag.name }}
                        </a>
                    </div>
                    <div slot="reference">
                        <div
                            :id="`${puzzles.id}-puzzles`"
                            class="truncate-container"
                            @mouseover="showToolTip(`${puzzles.id}-puzzles`)"
                            @mouseleave="hideTooltip"
                        >
                            <a
                                v-for="tag in puzzles.tags"
                                :key="tag.id"
                                class="suggestion-item-tags"
                                :href="getUrl(`${url}/puzzles?tag=${tag.slug}&`, medium)"
                                target="_blank"
                            >
                                {{ tag.name }}
                            </a>
                        </div>
                    </div>
                </el-popover>

                <SuggestionProperty
                    :stats="puzzlesStats"
                />
            </div>
            <a
                :class="`challenge-rank-${puzzles.rank} rank`"
                :href="getUrl(`${url}/puzzles?rank=${puzzles.rank}&`, medium)"
                target="_blank"
            >
                {{ puzzles.rank }}
            </a>
        </div>
    </div>
</template>

<script>
    import { mixin as clickaway } from '@tranxuanthang/vue-clickaway'
    import SuggestionProperty from '~/components/sidebar/Suggestion/InterviewQuestion/SuggestionProperty.vue'
    import visibleTag from '~/components/sidebar/Suggestion/visibleTag'

    export default {
        components: {
            SuggestionProperty,
        },

        mixins: [visibleTag, clickaway],

        props: {
            puzzles: Object,
            medium: String,
            modeTheme: String,
        },

        data() {
            return {
                visible: false,
            }
        },

        computed: {
            puzzlesStats() {
                return [
                    {
                        name: this.$t('stats.suggestion.puzzle.score').concat(`${this.puzzles.point}`),
                        icon: 'challenge-score',
                        value: this.puzzles.point,
                    },
                    {
                        name: this.$t('stats.suggestion.puzzle.completed_times')
                            .concat(`${this.puzzles.total_winner}`),
                        icon: 'challenge-completed-percent',
                        value: this.puzzles.total_winner,
                    },
                ]
            },

            url() {
                return process.env.CTF_URL
            },
        },

        mounted() {
            window.addEventListener('scroll', this.handleScroll)
        },

        destroyed() {
            window.removeEventListener('scroll', this.handleScroll)
        },

        methods: {
            handleScroll() {
                this.hideTooltip()
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
</style>
