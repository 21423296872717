// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="user-container">
        <slot v-if="user" :user="user" :userAction="userAction" />
    </div>
</template>

<script>
    import _curry from 'lodash/curry'
    import { mapState } from 'vuex'

    export default {
        props: {
            userId: {
                type: Number,
                required: true,
            },
        },

        computed: {
            ...mapState({
                user(state, getters) {
                    return getters['entities/users/get'](this.userId)
                },
            }),

            userAction() {
                return _curry(this.dispatchUserAction)
            },
        },

        methods: {
            dispatchUserAction(action, payload) {
                return this.$store.dispatch(`entities/users/${action}`, payload)
            },
        },
    }
</script>
