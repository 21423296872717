// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { absoluteUrl } from '~/lib/functions'

const defaultName = 'Viblo'
const defaultKeywords = 'Programmer, Knowledge Share, Programming, Document Management'
const homePageTitle = 'headSeo.homePageTitle'
const defaultDescription = 'Technology Articles Platform from Asia, filled with latest information on Programming Languages and Frameworks. Ruby on Rails / PHP / Swift / Unity / Java /.Net'
const defaultSEOImage = absoluteUrl('/og-facebook-3.png')
const defaultSocialUrls = ['https://www.facebook.com/viblo.asia/']

export {
    defaultName,
    defaultKeywords,
    homePageTitle,
    defaultSEOImage,
    defaultDescription,
    defaultSocialUrls,
}
