// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="sidebar__user">
        <div class="sidebar__user__row">
            <div class="d-flex position-relative flex-col justify-content-center">
                <Avatar
                    :image="user.avatar"
                    :object="user"
                    :is-user="true"
                    :level-partner="user.level_partner"
                    :partner-width="65"
                    :partner-hight="65"
                    :size="user.level_partner ? 'xl-mini' : 'xl'"
                />
            </div>

            <div class="d-flex flex-column justify-content-center word-break name-overflow width-name">
                <nuxt-link :to="profilePage" :class="{ 'is-banned' : user.banned_at}">
                    {{ user.name }}
                </nuxt-link>
                <span class="d-block">@{{ user.username }}</span>
                <div class="user-action mt-05">
                    <a
                        v-if="user.is_self"
                        :href="editProfile"
                        class="btn btn-follow btn-small-padding"
                        target="_blank"
                        rel="noopener"
                    >
                        {{ $t('userInline.edit') }}
                    </a>
                    <subscribe
                        v-else
                        ref="subscribe"
                        :send="handleFollow"
                        :subscribable="{ type: 'User', 'id': user.id }"
                    >
                        <button
                            :class="{following: user.following}"
                            :disabled="user.banned_at"
                            class="btn btn-follow btn-small-padding"
                            @click="$refs.subscribe.handle(!user.following)"
                        >
                            <span
                                v-if="user.following"
                            >
                                <i class="fa fa-check" />
                                {{ $t('userInline.following') }}
                            </span>
                            <span v-else><i class="fa fa-plus" /> {{ $t('userInline.follow') }} </span>
                        </button>
                    </subscribe>
                </div>
            </div>
        </div>

        <Stats
            :stats="userStats"
            class="stats-top-author d-flex justify-content-between text-nowrap align-items-center"
            :is-suggestion="isSuggestion"
        />
    </div>
</template>

<script>
    import _flow from 'lodash/flow'
    import _map from 'lodash/fp/map'
    import _filter from 'lodash/fp/filter'
    import { routeToUser } from '~/lib/functions'
    import Stats from '~/components/shared/Stats.vue'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import Subscribe from '../widgets/Subscribe.vue'

    const stats = {
        reputation: {
            attribute: 'reputation',
            tooltip: 'userInline.reputations',
            icon: 'fa fa-star',
        },
        followers: {
            attribute: 'followers_count',
            tooltip: 'userInline.followers',
            icon: 'fa fa-user-plus',
        },
        posts: {
            attribute: 'posts_count',
            tooltip: 'userInline.posts',
            icon: 'fa fa-pencil',
        },
        questions: {
            attribute: 'questions_count',
            tooltip: 'userInline.questions',
            icon: 'fa fa-question',
        },
        answers: {
            attribute: 'answers_count',
            tooltip: 'userInline.answers',
            icon: 'fa fa-reply',
        },
        postviews: {
            attribute: 'total_post_views',
            tooltip: 'userInline.totalPostViews',
            icon: 'fa fa-eye',
        },
    }

    export default {
        components: {
            Avatar,
            Stats,
            Subscribe,
        },

        props: {
            user: {
                type: Object,
                required: true,
            },

            stats: {
                type: Array,
                required: true,
            },

            follow: {
                type: Function,
                required: true,
            },
            isSuggestion: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            profilePage() {
                return routeToUser(this.user)
            },

            userStats() {
                return _flow(
                    _map(name => stats[name]),
                    _filter(Boolean),
                    _map(spec => ({
                        name: this.$t(`${spec.tooltip}`).concat(`${this.user[spec.attribute]}`),
                        icon: spec.icon,
                        value: this.user[spec.attribute],
                    }))
                )(this.stats)
            },

            editProfile() {
                return `${process.env.SSO_APP_URL}/profile/personal`
            },
        },

        methods: {
            handleFollow(value) {
                return this.follow({ username: this.user.username, value })
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    @import "~assets/sass/bootstrap/borders";

    .sidebar__user {
        &__row {
            display: flex;
            margin-bottom: 10px;

            & > * {
                &:nth-child(1) {
                    min-width: 80px;
                    max-width: 80px;
                    text-align: center;
                    flex-grow: 1;
                }

                &:nth-child(2) {
                    margin-left: 10px;
                }
            }
        }
    }

    .avatar-author {
        margin-left: 12px;
    }

    .sidebar-user-action {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .following {
        min-width: 100px;
    }

    .stats-top-author {
        margin: 0 0 0 15px;
    }

    .width-name {
        width: 170px !important;
    }

    @media only screen and (max-width: 1200px) {
        .sidebar-user-action {
            display: flex;
            flex-direction: column;

            .stats-top-author {
                justify-content: space-evenly;
                margin: 5px;
            }

            .user-action {
                margin: 5px 20px 20px 20px;
            }
        }
    }
</style>
