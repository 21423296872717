// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <MainBanner />

        <div id="comment-section" class="container post-section mb-2">
            <h3 class="post-section-title cross-line my-2">
                <strong>{{ $t('authorsRecommend.title') }}</strong>
            </h3>

            <div v-if="authors.length === 0" class="text-center font-weight-bold text-muted my-05">
                <slot>{{ $t('authorsRecommend.content') }}</slot>
            </div>

            <div v-else>
                <div class="block-exhibition row mt-3">
                    <div v-for="(user, index) in authors" :key="index" class="col-sm-6 col-md-4 avatar-min-md">
                        <user :user="user" :follow="follow" />
                    </div>
                </div>

                <pagination
                    :current-page="pagination.current_page"
                    :last-page="pagination.total_pages"
                    :path="path"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { handlePageError } from '~/utils/pages'
    import { getRelatedAuthors } from '~/api/placements'
    import { normalizeUserList } from '~/components/entities/normalize'

    import MainBanner from '~/components/frontPage/MainBanner.vue'
    import User from '~/components/users/ProfileCard.vue'
    import Pagination from '~/components/Pagination.vue'

    export default {
        components: {
            MainBanner,
            User,
            Pagination,
        },

        middleware: ['auth'],

        data() {
            return {
                pagination: {
                    current_page: 1,
                    total_pages: 0,
                },
            }
        },

        computed: {
            ...mapGetters('auth', ['authenticated']),

            authors() {
                return this.$store.getters['entities/users/mapId'](this.authorIds)
            },

            page() {
                return (typeof this.$route.query.page === 'undefined') ? 1 : this.$route.query.page
            },

            path() {
                return this.$route.path
            },
        },

        async asyncData({
            store, error, query,
        }) {
            try {
                const page = (typeof query.page === 'undefined') ? 1 : query.page

                const { relatedAuthors, pagination } = await getRelatedAuthors({ page, limit: 30 })
                    .then(response => ({
                        relatedAuthors: response.data.data,
                        pagination: response.data.meta.pagination,
                    }))

                const normalizedAuthors = normalizeUserList(relatedAuthors)

                const authorIds = normalizedAuthors.result

                store.commit('entities/users/put', normalizedAuthors.entities.users)

                return { authorIds, pagination }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        watchQuery: ['page'],

        methods: {
            ...mapActions('entities/users', ['follow']),
        },
    }
</script>
