// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="mt-1">
        <div v-on-clickaway="hideTooltip" class="header-challenge">
            <div
                class="left-box"
                :style="{
                    width: !challenge.rank ? `100%` : `80%`
                }"
            >
                <a
                    :href="getUrl(`${challenge.link}?`, medium)"
                    target="_blank"
                    :class="modeTheme === 'theme-dark' ? 'dark-mode-color' : 'suggestion-item-content'"
                >
                    {{ challenge.title }}
                </a>
                <p class="time-published text-muted">
                    {{ challenge.created_at | humanize-time }}
                </p>
                <el-popover
                    v-if="challenge && challenge.tags.length > 0"
                    v-model="visible"
                    trigger="manual"
                    placement="top-start"
                    width="200"
                >
                    <div
                        class="d-flex flex-wrap"
                        @mouseover="showToolTip(`${challenge.id}-challenges`)"
                        @mouseleave="hideTooltip"
                    >
                        <a
                            v-for="tag in challenge.tags"
                            :key="tag"
                            class="suggestion-item-tags"
                            :href="getUrl(`${url}/challenges?tag=${tag}&`, medium)"
                            target="_blank"
                        >
                            {{ tag }}
                        </a>
                    </div>
                    <div slot="reference">
                        <div
                            :id="`${challenge.id}-challenges`"
                            class="truncate-container"
                            @mouseover="showToolTip(`${challenge.id}-challenges`)"
                            @mouseleave="hideTooltip"
                        >
                            <a
                                v-for="tag in challenge.tags"
                                :key="tag"
                                class="suggestion-item-tags"
                                :href="getUrl(`${url}/challenges?tag=${tag}&`, medium)"
                                target="_blank"
                            >
                                {{ tag }}
                            </a>
                        </div>
                    </div>
                </el-popover>

                <SuggestionProperty
                    :stats="challengeStats"
                />
            </div>
            <a
                :class="`challenge-rank-${challenge.rank} rank`"
                :href="getUrl(`${url}/challenges?rank=${challenge.rank}&`, medium)"
                target="_blank"
            >
                {{ challenge.rank }}
            </a>
        </div>
    </div>
</template>

<script>
    import { mixin as clickaway } from '@tranxuanthang/vue-clickaway'
    import SuggestionProperty from '~/components/sidebar/Suggestion/InterviewQuestion/SuggestionProperty.vue'
    import visibleTag from '~/components/sidebar/Suggestion/visibleTag'

    export default {
        components: {
            SuggestionProperty,
        },

        mixins: [visibleTag, clickaway],

        props: {
            challenge: Object,
            medium: String,
            modeTheme: String,
        },

        computed: {
            completionRatePercent() {
                return this.challenge.attempted_count === 0 ? 0
                    : Math.round(this.challenge.completed_times / this.challenge.attempted_count * 100)
            },

            achievementRatePercent() {
                return this.challenge.attempted_count === 0 ? 0
                    : Math.round(this.challenge.trophied_count / this.challenge.attempted_count * 100)
            },

            challengeStats() {
                return [
                    {
                        name: this.$t('stats.suggestion.challenge.score').concat(`${this.challenge.score}`),
                        icon: 'challenge-score',
                        value: this.challenge.score,
                    },
                    {
                        name: this.$t('stats.suggestion.challenge.completed_times')
                            .concat(`${this.completionRatePercent}`),
                        icon: 'challenge-completed-percent',
                        value: this.completionRatePercent,
                    },
                    {
                        name: this.$t('stats.suggestion.challenge.trophied_count').concat(`${this.achievementRatePercent}`),
                        icon: 'challenge-trophied-percent',
                        value: this.achievementRatePercent,
                    },
                ]
            },

            url() {
                return process.env.CODE_URL
            },
        },

        mounted() {
            window.addEventListener('scroll', this.handleScroll)
        },

        destroyed() {
            window.removeEventListener('scroll', this.handleScroll)
        },

        methods: {
            handleScroll() {
                this.hideTooltip()
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    .sidebar__feed-item {
        padding-bottom: .5rem;
        margin-bottom: .5rem;
        border-bottom: 1px solid $gray-200;

        &__info{
            text-align: center;
            display: inline-block;
            div{
                float: left;
            }
        }

        &:last-of-type{
            border-bottom:none;
        }

        &__subtitle{
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .header-challenge {
            display: flex;
            justify-content: space-between;

            .rank {
                padding: 2px 10px;
                height: fit-content;
                border-radius: 10px;
                font-size: large;
                font-weight: bold;

                &:hover {
                    text-decoration: none;
                }
            }

            .left-box {
                display: flex;
                flex-direction: column;
            }
        }

        .challenge-rank-S {
            color: #f5be1b;
            border: 2px solid #f5be1b;
            background:  rgba(245, 190, 27, 0.16);

        }

        .challenge-rank-A {
            color: #DF6469;
            border: 2px solid #DF6469;
            background:  rgba(223, 100, 105, 0.16);
        }

        .challenge-rank-B {
            color: #5B0285;
            border: 2px solid #5B0285;
            background:  rgba(91, 2, 133, 0.16);
        }

        .challenge-rank-C {
            color: #57B4F9;
            border: 2px solid #57B4F9;
            background:  rgba(87, 180, 249, 0.16);
        }

        .challenge-rank-D {
            color: #0066B8;
            border: 2px solid #0066B8;
            background:  rgba(0, 102, 184, 0.16);
        }

        .challenge-rank-E {
            color: #80AD69;
            border: 2px solid #80AD69;
            background: rgba(128, 173, 105, 0.16);
        }

        .challenge-rank-F {
            color: #514954;
            border: 2px solid #514954;
            background: rgba(81, 73, 84, 0.16);
        }
    }

    .dark-mode-color {
        color: #c9d1d9 !important;
    }
</style>
