// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export default [
    {
        name: 'posts-may-fest',
        path: '/mayfest2024',
        title: 'feedBar.mayfestTitle',
        auth: false,
        feedType: 'mayfest',
    },
    {
        name: 'posts-content-creator',
        path: '/content-creator',
        title: 'feedBar.contentCreator',
        auth: false,
        feedType: 'contentcreator',
    },
    {
        name: 'posts-newest',
        path: '/newest',
        title: 'feedBar.newest',
        auth: false,
        feedType: 'homepage',
    },
    {
        name: 'posts-subscriptions',
        path: '/followings',
        title: 'feedBar.followings',
        auth: true,
        feedType: 'followings',
    },
    {
        name: 'posts-series',
        path: '/series',
        title: 'feedBar.series',
        auth: false,
        feedType: 'series',
    },
    {
        name: 'posts-editors-choice',
        path: '/editors-choice',
        title: 'feedBar.editorsChoice',
        auth: false,
        feedType: 'editors-choice',
    },
    {
        name: 'posts-trending',
        path: '/trending',
        title: 'feedBar.trending',
        auth: false,
        feedType: 'trending',
    },
    {
        name: 'posts-videos',
        path: '/videos',
        title: 'feedBar.videos',
        auth: false,
        feedType: 'videos',
    },
    {
        name: 'posts-clip',
        path: '/clip/posts',
        title: 'feedBar.clip',
        feedType: 'clip',
        alias: '/clip/series',
        auth: true,
    },
]
