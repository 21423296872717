// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <PostFeedPlaceholder
        v-if="$fetchState.pending && isEmpty && startEventMayFest"
    />

    <PostFeed
        v-else-if="startEventMayFest"
        :should-highlight="isNewPost"
        :pinned-item="pinnedItem"
        store="postHomeFeed"
    >
        <div
            v-if="showNewPosts"
            slot="before"
            class="load-new-posts p-05 m-0 text-center cursor-pointer"
            @click="loadNewPosts"
        >
            <i v-if="loadingNewPosts" class="el-icon-loading" />
            {{ $t('seeNewPosts.firstContent') }} {{ newPosts }} {{ $t('seeNewPosts.lastContent') }}
        </div>
    </PostFeed>

    <div v-else>
        <h1 class="article-content__title mb-2">
            [5 Years Anniversary] Viblo May Fest 2020
        </h1>

        <p style="font-size:1rem;">
            Tiếp nối các hoạt động trong chuỗi sự kiện kỷ niệm 5 năm thành lập,
            tháng 5 này, các thành viên có cơ hội thể hiện mình qua những bài viết tâm huyết
            ngay trên nền tảng Viblo qua sự kiện
            <a href="https://mayfest.viblo.asia" target="_blank" rel="noopener">
                <strong>Viblo May Fest 2020</strong>
            </a>.
            Với mong muốn nhận được sự đóng góp của tất cả các kỹ sư, lập trình viên dù đang học tập
            và nghiên cứu ở bất cứ trình độ và lĩnh vực nào.
        </p>

        <p style="font-size:1rem;">
            Rinh ngay về những phần quà vô cùng ý nghĩa khi publish 4 bài viết trong tháng 5 này.
            "Lên sẵn bài" từ bây giờ để rồi tới tháng thì publish ngay và "hốt" quà. Hãy cùng nhau chia sẻ kiến thức,
            đóng góp vào sự phát triển của Viblo nói riêng và cộng đồng IT Việt Nam nói chung!
            Nhấn vào nút dưới đây để biết thêm thông tin chi tiết.
        </p>

        <p style="font-size:1rem;">
            <a href="https://mayfest.viblo.asia" target="_blank" rel="noopener">
                <el-button type="primary" class="text-uppercase">
                    Tìm hiểu ngay
                </el-button>
            </a>
        </p>
    </div>
</template>

<script>
    import { isAfter } from 'date-fns'
    import { mapState, mapGetters } from 'vuex'
    import { feedSEO } from '~/utils/seo'
    import frontPage from '~/pages/__mixins/frontPage'
    import dynamicFetching from '~/pages/__mixins/dynamicFetching'

    import PostFeed from '~/containers/posts/PostFeed.vue'
    import PostFeedPlaceholder from '~/containers/posts/PostFeedPlaceholder.vue'

    export default {
        name: 'MayFestPosts',

        components: {
            PostFeed,
            PostFeedPlaceholder,
        },

        mixins: [
            frontPage('may-fest', { visitFeed: 'may-fest' }),
            dynamicFetching(),
        ],

        data: () => ({
            loadingNewPosts: false,
        }),

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapGetters('postHomeFeed', ['isEmpty']),
            ...mapState('announcements', ['pinnedItem']),
            ...mapState('toast', ['newPosts']),

            showNewPosts() {
                const onFirstPage = !this.$route.query.page || this.$route.query.page <= 1

                return onFirstPage && this.newPosts > 0 && process.client
            },

            startEventMayFest() {
                return isAfter(new Date(), new Date(2020, 4, 1))
            },
        },

        fetch() {
            return this.withProgressBar(() => {
                if (process.client) {
                    this.$store.commit('toast/clearNewPost')
                }

                if (!this.pinnedItem) {
                    this.$store.dispatch('announcements/getPinnedItem')
                }

                return this.$store.dispatch('postHomeFeed/fetch', {
                    feed: 'may-fest',
                    params: {
                        ...this.$route.query,
                        limit: this.$store.state.settings.perPage,
                    },
                })
            })
        },

        watchQuery: ['page'],

        methods: {
            loadNewPosts() {
                this.$nuxt.$loading.start()
                this.loadingNewPosts = true
                this.$store.dispatch('postHomeFeed/fetch', { feed: 'may-fest' })
                    .then(() => {
                        this.$nuxt.$loading.finish()
                        this.loadingNewPosts = false
                        this.$store.commit('toast/clearNewPost')
                    })
            },

            isNewPost({ published_at: publishedAt, user_id: userId }) {
                if (!this.lastSeen || this.$store.getters['auth/isUser'](userId) || !publishedAt) return false
                return new Date(this.lastSeen) < new Date(publishedAt)
            },
        },

        head() {
            const seo = feedSEO('may-fest')
            seo.title = this.withUnreadCount(seo.title)
            return seo
        },
    }
</script>

<style lang="scss">
    .load-new-posts {
        background-color: #eee;

        &:hover {
            background-color: #ddd;
        }
    }
</style>
