// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-ctr
        v-if="banner"
        :name="`Main banner - ${banner.name}`"
        class="promo__banner"
    >
        <a
            :href="banner.link"
            :aria-ctr-enable="banner.name"
            target="_blank"
            rel="noopener"
        >
            <div :style="{ 'background-color': banner.background_color }">
                <progressive-img
                    :tiny-src="tinyUrl"
                    :src="banner.image"
                    :width="1140"
                    :height="168"
                    alt=""
                    class="promo__banner__image"
                />
            </div>
        </a>
    </v-ctr>
</template>

<script>
    import { mapGetters } from 'vuex'
    import _escapeRegExp from 'lodash/escapeRegExp'

    export default {
        data() {
            return {
                tinyUrl: null,
            }
        },

        computed: {
            ...mapGetters('config', { banner: 'currentBanner' }),
        },

        created() {
            if (this.banner && this.banner.image) {
                const matches = this.banner.image.match(new RegExp(String.raw`^${_escapeRegExp(process.env.IMAGES_URL)}\/*[a-z0-9]*\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})\.([a-z]+)$`), 'i')
                if (matches) {
                    const uuid = matches[1]
                    const extension = matches[2]
                    this.tinyUrl = `${process.env.IMAGES_URL}/60/${uuid}.${extension}`
                }
            }
        },
    }
</script>

<style lang="scss">
    .promo__banner {
        &__image {
            width: 100%;
        }
    }
</style>
