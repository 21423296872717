// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="container pb-1">
        <v-row class="pt-3 mb-3 title-line">
            <v-col xs="12" lg="3" class="d-flex align-items-center mb-1">
                <div class="section-title-line full-width">
                    <h4 class="text-uppercase">
                        {{ $t('getStarted.tag') }} ({{ pagination.total }})
                    </h4>
                    <hr class="mx-1 filler-line">
                </div>
            </v-col>
            <v-col
                xs="12"
                sm="6"
                md="6"
                lg="3"
                class="mb-1"
            >
                <span class="mr-1">{{ $t('getStarted.sort.title') }}</span>
                <el-select
                    :value="sort"
                    class="el-select--small"
                    @change="(value) => applyChanges('sort', value)"
                >
                    <el-option
                        v-for="(option, key, index) in sort_options"
                        :key="index"
                        :label="option"
                        :value="key"
                    >
                        {{ option }}
                    </el-option>
                </el-select>
            </v-col>
            <v-col xs="12" lg="4" class="mb-1">
                <div class="sb">
                    <input
                        v-model="q"
                        class="sb__input"
                        :placeholder="$t('getStarted.search')"
                        @change="applyChanges('q', q)"
                    >
                    <button class="btn btn-primary" @click.prevent="applyChanges('q', q)">
                        <i class="fa fa-search" />
                    </button>
                </div>
            </v-col>
            <v-col
                xs="12"
                sm="6"
                md="6"
                lg="2"
                class="mb-1"
            >
                <el-button
                    :disabled="followings <5"
                    type="primary"
                    size="large"
                    @click="$router.push('/')"
                >
                    {{ $t(continueable) }}
                </el-button>
            </v-col>
        </v-row>

        <empty v-if="tags.length <= 0">
            <p> {{ $t('empty') }} </p>
        </empty>

        <div v-else>
            <div class="row block-exhibition">
                <div v-for="(tag, index) in tags" :key="index" class="col-sm-6 col-lg-4 my-2 exhibition-item">
                    <tag-item :tag="tag" />
                </div>
            </div>
            <pagination
                :current-page="pagination.current_page"
                :last-page="pagination.total_pages"
                :path="path"
                :query="paginationQuery"
            />
        </div>
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import { mapGetters, mapState } from 'vuex'
    import { getTags } from 'viblo-sdk/api/tags'
    import { handlePageError } from '~/utils/pages'
    import { normalizeTagList } from '~/components/entities/normalize'

    import Empty from '~/components/shared/Empty.vue'
    import TagItem from '~/components/tags/Card.vue'
    import Pagination from '~/components/Pagination.vue'

    const filterFromQuery = query => ({
        page: (typeof query.page === 'undefined') ? 1 : query.page,
        sort: (typeof query.sort === 'undefined') ? 'name_ASC' : query.sort,
        sort_type: (typeof query.sort === 'undefined' || query.sort === 'name_ASC') ? 'ASC' : 'DESC',
        type: 'technical',
        q: (typeof query.q === 'undefined') ? '' : query.q,
    })

    export default {
        components: {
            Empty,
            TagItem,
            Pagination,
        },

        middleware: ['auth'],

        data() {
            const q = this.$route.query.q

            return {
                sort_options: {
                    name_ASC: this.$t('getStarted.sort.nameASC'),
                    name_DESC: this.$t('getStarted.sort.nameDESC'),
                    followers: this.$t('getStarted.sort.followers'),
                    posts: this.$t('getStarted.sort.posts'),
                    questions: this.$t('getStarted.sort.question'),
                },
                q,
                pagination: {
                    current_page: 1,
                    total_pages: 0,
                },
                centerDialogVisible: true,
            }
        },

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapGetters('auth', ['authenticated']),
            ...mapState('auth', ['user']),

            tags() {
                return this.$store.getters['entities/tags/mapId'](this.tagIds)
            },

            path() {
                return this.$route.path
            },

            sort() {
                return (typeof this.$route.query.sort === 'undefined') ? 'name_ASC' : this.$route.query.sort
            },

            paginationQuery() {
                return {
                    sort: this.sort,
                }
            },

            followings() {
                return this.$store.state.auth.user.following_technicals_count
            },

            continueable() {
                if (this.followings === 0) {
                    return 'getStarted.continueAble.follow'
                }

                if (this.followings < 5) {
                    return this.$t('getStarted.continueAble.followed').concat(`${this.followings}/${this.pagination.total}`)
                }

                return 'getStarted.continueAble.continue'
            },
        },

        async asyncData({
            store, error, query,
        }) {
            try {
                const filters = filterFromQuery(query)
                const { tags, pagination } = await getTags(filters)
                    .then(response => ({
                        tags: response.data,
                        pagination: response.meta.pagination,
                    }))

                const normalizedTags = normalizeTagList(tags)
                const tagIds = normalizedTags.result

                store.commit('entities/tags/put', normalizedTags.entities.tags)

                return { tagIds, pagination }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        watchQuery: ['page', 'sort', 'q'],

        methods: {
            applyChanges(key, value) {
                const page = key === 'sort' || key === 'q' ? 1 : (this.$route.query.page || 1)
                const query = _assign({}, this.$route.query, {
                    [key]: value,
                    page,
                })
                this.$router.push({ path: '/get-started', query })
            },
        },

        beforeRouteLeave(to, from, next) {
            if (this.followings >= 5) return next()

            return this.$confirm(this.$t('getStarted.beforeRouteLeave.content'), this.$t('getStarted.beforeRouteLeave.title'), {
                confirmButtonText: this.$t('confirm.ok'),
                cancelButtonText: this.$t('confirm.cancel'),
            })
        },

        head() {
            return {
                title: this.withUnreadCount(this.$t('getStarted.start')),
            }
        },
    }

</script>

<style scoped>
    .el-select--small {
        max-width: 9rem;
    }

    .prompt_follow_title {
        color: #f56c6c;
    }

    .title-line {
        position: sticky;
        top: 60px;
        z-index: 80;
        background-color: #fff;
    }
</style>
