// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div id="comment-section" class="container post-section mb-2">
        <h3 class="post-section-title cross-line my-2">
            <strong>{{ $t('authorsRecommend.title') }}</strong>
        </h3>

        <div v-if="users.length === 0" class="text-center font-weight-bold text-muted my-05">
            <slot>{{ $t('authorsRecommend.content') }}</slot>
        </div>

        <div v-else>
            <div class="block-exhibition row mt-3">
                <div v-for="(user, index) in users" :key="index" class="col-sm-6 col-md-4 avatar-min-md">
                    <user :user="user" :follow="follow" />
                </div>
            </div>

            <div class="text-center font-weight-bold mt-1">
                <nuxt-link to="/explore/authors">
                    {{ $t('authorsRecommend.seeMore') }}
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapActions } from 'vuex'
    import User from '~/components/users/ProfileCard.vue'

    export default {
        components: {
            User,
        },

        props: {
            users: {
                type: Array,
            },
        },

        methods: {
            ...mapActions('entities/users', ['follow']),
        },
    }
</script>

<style lang="css">
    @keyframes flash {
        50% {
            background-color: rgba(75, 205, 159, 0.25);
        }
    }

    .cross-line {
        overflow: hidden;
        position: relative;
    }
</style>
