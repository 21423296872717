// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <div class="d-flex search-sort-bar align-items-baseline justify-content-between mb-1">
            <el-dropdown trigger="click">
                <span class="cursor-pointer">
                    {{ $t('myClips.type') }} <b>{{ $t(activeType.label) }}</b>
                </span>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(entityType, key) in entityTypes"
                        :key="key"
                        :class="{ 'el-dropdown-menu__item--active': entityType == activeType }"
                        @click.native="sort(entityType)"
                    >
                        {{ _upperFirst($t(entityType.label)) }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown trigger="click">
                <span class="cursor-pointer">
                    {{ $t('myClips.sort') }} <b>{{ $t(activeSort.label) }}</b>
                </span>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(type, key) in sortTypes"
                        :key="key"
                        :class="{ 'el-dropdown-menu__item--active': type.field == activeSort.field }"
                        @click.native="sort(null, type)"
                    >
                        {{ $t(type.label) }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <list-mix
            :data="bookmarks"
            :type="type"
            :pagination="pagination"
            :state="$fetchState.pending"
        />
    </div>
</template>

<script>
    import _filter from 'lodash/filter'
    import _upperFirst from 'lodash/upperFirst'
    import { mapGetters, mapState } from 'vuex'
    import { feedSEO } from '~/utils/seo'

    import ListMix from '~/components/ListMix.vue'

    const entityTypes = [
        {
            label: 'myClips.posts',
            values: 'posts',
        },
        {
            label: 'myClips.series',
            values: 'series',
        },
    ]

    const sortTypes = [
        {
            label: 'myClips.published',
            field: 'published_at',

        },
        {
            label: 'myClips.clipAt',
            field: 'clipped_at',
        },
    ]

    export default {
        name: 'BookmarksListPage',

        // Disable progress bar because we have placeholder animated.
        // If 'loading: true', the progress bar does not match
        // loading: false,

        middleware: 'auth',

        head() {
            const head = feedSEO(`clip-${this.type}`)

            head.title = this.withUnreadCount(head.title)

            return head
        },

        components: {
            ListMix,
        },

        data() {
            return {
                sortTypes,
                entityTypes,
            }
        },

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapState('bookmarks', ['bookmarks', 'pagination', 'type']),

            activeType() {
                const active = this.$route.params.clip || 'posts'
                return _filter(entityTypes, type => type.values === active)[0]
            },

            activeSort() {
                const active = this.$route.query.sort || 'published_at'
                return _filter(sortTypes, type => type.field === active)[0]
            },
        },

        watch: {
            '$route.query': '$fetch',
        },

        fetchOnServer: false,

        async fetch() {
            // Although 'loading: fale', progress bar still displays.
            // So we need to update progress bar mannually for better UX
            if (this.$nuxt.$loading.start) {
                this.$nuxt.$loading.start().increase(5)
            }
            const type = this.$route.params.clip
            const sort = this.$route.query.sort || 'published_at'
            const page = this.$route.query.page || 1
            const username = this.$store.state.auth.user.username
            const perPage = this.$store.state.settings.perPage

            this.$store.dispatch('bookmarks/getBookmarks', {
                sort, page, perPage, username, type,
            })
        },

        watchQuery: true,

        methods: {
            _filter,
            _upperFirst,
            sort(entityType, sortType) {
                entityType = entityType || this.activeType
                sortType = sortType || this.activeSort

                this.$router.push(`/clip/${entityType.values}?sort=${sortType.field}`)
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "../../assets/sass/bootstrap/colors";
    @import "../../assets/sass/bootstrap/borders";

    .badge {
        margin: 5px;
        background: none;
        &.active {
            background: #5488c7;
            color: white;
        }
    }

    .search-sort-bar {
        .btn-secondary:active,
        .btn-secondary.active,
        .open > .btn-secondary.dropdown-toggle {
            background: #FFF;
        }

        .dropdown-menu {
            padding: 0;

            &.width-x2 {
                width: 200%;
            }

            &.width-x3 {
                width: 300%;
            }

            .dropdown-header {
                font-weight: bold;
                color: $primary;
                background-color: lighten($primary, 30%);
            }
        }

        .dropdown-item {
            padding: 8px 2.3rem;
            border-bottom: 1px solid $base-border-color;
            cursor: pointer;

            &:last-child {
                border-bottom: 0 none;
                border-radius: 0 0 0.25rem 0.25rem;
            }

            &.selected {
                font-weight: bold;

                .fa {
                    margin-right: 0.5rem;
                }
            }

            &:hover {
                background-color: $primary;
                color: #fff;
            }
        }
    }
</style>
