// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <div class="search-box mb-1">
            <div class="d-flex">
                <el-input
                    v-model="value"
                    size="medium"
                    class="mr-1"
                    placeholder="Search Viblo"
                    @keyup.native.enter="search"
                />

                <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-search"
                    @click="search"
                >
                    {{ $t('search.title') }}
                </el-button>
            </div>
        </div>

        <div v-show="query" class="search-options mb-1">
            <heading-tabs :active-tab-index="activeTabIndex">
                <heading-tabs-item :to="setTab('posts')" index="posts">
                    {{ $t('search.posts') }}
                </heading-tabs-item>
                <heading-tabs-item :to="setTab('questions')" index="questions">
                    {{ $t('search.questions') }}
                </heading-tabs-item>
                <heading-tabs-item :to="setTab('users')" index="users">
                    {{ $t('search.authors') }}
                </heading-tabs-item>
            </heading-tabs>

            <el-dropdown v-if="activeTabIndex !== 'users'" trigger="click">
                <div class="text-muted cursor-pointer">
                    {{ $t('search.sort.label') }}: <b class="text-primary">{{ activeSortLabel }}</b>
                    <i class="fa fa-caret-down ml-05" />
                </div>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(sorting, index) in sortingOptions"
                        :key="index"
                        :class="{ 'el-dropdown-menu__item--active': activeSortIndex === index }"
                        @click.native="setSorting(sorting.params)"
                    >
                        {{ $t(`search.sort.${sorting.label}`) }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _findIndex from 'lodash/findIndex'
    import HeadingTabs from '~/components/shared/HeadingTabs.vue'
    import HeadingTabsItem from '~/components/shared/HeadingTabsItem.vue'

    const sortingOptions = [
        { label: 'best_match', params: { s: 'score' } },
        { label: 'clipped', params: { s: 'clip' } },
        { label: 'viewed', params: { s: 'views' } },
        { label: 'rated', params: { s: 'rate' } },
        { label: 'commented', params: { s: 'comments' } },
        { label: 'newest', params: { s: 'created', o: 'desc' } },
        { label: 'oldest', params: { s: 'created', o: 'asc' } },
    ]

    export default {

        components: {
            HeadingTabs,
            HeadingTabsItem,
        },
        props: {
            query: String,
            sort: String,
            order: String,
            type: {
                type: String,
                default: 'posts',
            },
        },

        data() {
            return {
                sortingOptions,
                value: this.query,
            }
        },

        computed: {
            activeSortIndex() {
                const matchedIndex = _findIndex(
                    sortingOptions,
                    ({ params }) => params.s === this.sort && (!params.o || params.o === this.order)
                )

                return matchedIndex > -1 ? matchedIndex : 0
            },

            activeSortLabel() {
                const label = _get(sortingOptions, `${this.activeSortIndex}.label`)

                return this.$t(`search.sort.${label}`)
            },

            activeTabIndex() {
                return this.$route.query.type || 'posts'
            },
        },

        watch: {
            query(to) {
                this.value = to
            },
        },

        methods: {
            search() {
                if (this.value !== this.query) {
                    this.emitSearch({
                        query: { q: this.value },
                        append: true,
                    })
                }
            },

            setSorting(params) {
                this.emitSearch({
                    query: params,
                    append: true,
                })
            },

            setTab(tab) {
                return {
                    query: {
                        ...this.$route.query,
                        type: tab,
                    },
                }
            },

            emitSearch(payload) {
                this.$emit('search', payload)
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    @import "~assets/sass/bootstrap/borders";

    .sort-options {
        .dropdown-menu {
            padding: 0;
            overflow: hidden;
        }

        .dropdown-item {
            padding: 8px 1rem;

            &:not(:last-child) {
                border-bottom: 1px solid $base-border-color;
            }

            &.selected {
                font-weight: bold;
            }

            &:hover {
                background-color: $primary;
                color: #fff;
            }
        }
    }

    .search-options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $gray-lighter;
    }
</style>
