// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="mt-1">
        <div class="d-flex flex-column">
            <div v-on-clickaway="hideTooltip" class="header-course">
                <div
                    class="left-box"
                    :style="{
                        width: !course.level ? `100%` : `70%`
                    }"
                >
                    <a
                        :href="getUrl(`${course.link}?`, medium)"
                        target="_blank"
                        :class="modeTheme === 'theme-dark' ? 'dark-mode-color' : 'suggestion-item-content'"
                    >
                        {{ course.name }}
                    </a>
                    <p class="time-published text-muted">
                        {{ course.createdAt | humanize-time }}
                    </p>
                </div>
                <a
                    :class="`course-level ${course.level}`"
                    :href="getUrl(`${url}/courses?level=${course.level}&`, medium)"
                    target="_blank"
                >
                    {{ $t(`sidebar.suggestion.course.level.${course.level}`) }}
                </a>
            </div>
            <p class="time-published text-muted">
                {{ course.published_at | humanize-time }}
            </p>
            <el-popover
                v-if="course.tags.length > 0"
                v-model="visible"
                trigger="manual"
                placement="top-start"
                width="200"
            >
                <div
                    class="d-flex flex-wrap"
                    @mouseover="showToolTip(`${course.id}-course`)"
                    @mouseleave="hideTooltip"
                >
                    <a
                        v-for="tag in course.tags"
                        :key="tag"
                        class="suggestion-item-tags"
                        :href="getUrl(`${url}/courses?tag=${tag}&`, medium)"
                        target="_blank"
                    >
                        {{ tag }}
                    </a>
                </div>
                <div slot="reference">
                    <div
                        :id="`${course.id}-course`"
                        class="truncate-container"
                        @mouseover="showToolTip(`${course.id}-course`)"
                        @mouseleave="hideTooltip"
                    >
                        <a
                            v-for="tag in course.tags"
                            :key="tag"
                            class="suggestion-item-tags"
                            :href="getUrl(`${url}/courses?tag=${tag}&`, medium)"
                            target="_blank"
                        >
                            {{ tag }}
                        </a>
                    </div>
                </div>
            </el-popover>
            <SuggestionProperty
                :course="course"
                :stats="learnCourseStarts"
            />
        </div>
    </div>
</template>

<script>
    import { mixin as clickaway } from '@tranxuanthang/vue-clickaway'
    import SuggestionProperty from '~/components/sidebar/Suggestion/InterviewQuestion/SuggestionProperty.vue'
    import visibleTag from '~/components/sidebar/Suggestion/visibleTag'

    export default {
        components: {
            SuggestionProperty,
        },

        mixins: [visibleTag, clickaway],

        props: {
            course: Object,
            medium: String,
            modeTheme: String,
        },

        computed: {
            learnCourseStarts() {
                return [
                    {
                        name: this.$t('stats.suggestion.course.view').concat(`${this.course.viewsCount}`),
                        icon: 'question-view-count',
                        value: this.course.viewsCount,
                    },
                    {
                        name: this.$t('stats.suggestion.course.user_count').concat(`${this.course.totalUsers}`),
                        icon: 'course-user-count',
                        value: this.course.totalUsers,
                    },
                    {
                        name: this.$t('stats.suggestion.course.question_count').concat(`${this.course.publishedQuestions}`),
                        icon: 'course-question-count',
                        value: this.course.publishedQuestions,
                    },
                    {
                        name: this.$t('stats.suggestion.course.test_count').concat(`${this.course.totalTests}`),
                        icon: 'course-exam-count',
                        value: this.course.totalTests,
                    },
                ]
            },

            url() {
                return process.env.LEARN_URL
            },
        },

        mounted() {
            window.addEventListener('scroll', this.handleScroll)
        },

        destroyed() {
            window.removeEventListener('scroll', this.handleScroll)
        },

        methods: {
            handleScroll() {
                this.hideTooltip()
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    .sidebar__feed-item {
        padding-bottom: .5rem;
        margin-bottom: .5rem;
        border-bottom: 1px solid $gray-200;

        &__info{
            text-align: center;
            display: inline-block;
            div{
                float: left;
            }
        }

        &:last-of-type{
            border-bottom:none;
        }

        &__subtitle{
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .header-course {
            display: flex;
            justify-content: space-between;

            .course-level {
                padding: 1px 10px;
                height: fit-content;
                border-radius: 5px;
                font-size: 12px;
                color: white;
                font-weight: bold;

                &:hover {
                    text-decoration: none;
                }
            }

            .left-box {
                display: flex;
                flex-direction: column;
            }

            @media screen and (max-width: 1180px) {
                .course-level {
                    font-size: 12px;
                }
            }
        }

        .beginning {
            background: #41A5F9;

        }

        .advanced {
            background: #FF7272;
        }

        .intermediate {
            background: #00C48C;
        }
    }
</style>
