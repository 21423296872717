// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export default [
    {
        name: 'questions-newest',
        path: '/questions',
        alias: '/',
        title: 'question.newest',
        auth: false,
        feedType: 'questions-newest',
    },
    {
        name: 'questions-unsolved',
        path: '/questions/unsolved',
        title: 'question.unsolved',
        auth: false,
        feedType: 'unsolved',
    },
    {
        name: 'questions-followings',
        path: '/questions/followings',
        title: 'question.followings',
        auth: true,
        feedType: 'following',
    },
    {
        name: 'questions-clips',
        path: '/questions/clips',
        title: 'question.myClips',
        auth: true,
        feedType: 'questions-clip',
    },
]
