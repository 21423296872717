// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="item d-flex py-1 mt-05 company-show">
        <Avatar
            :image="organization.avatar"
            :object="organization"
            :level-partner="organization.level_partner"
            :partner-width="95"
            :partner-hight="95"
            size="xxl"
            image-size="avatar"
            :class="organization.level_partner ? 'mr-05' : 'margin-content'"
        />

        <div class="right-col">
            <div class="company content-limit">
                <div class="d-flex align-items-center">
                    <div class="company-item-name word-break">
                        <nuxt-link :to="toOrganization(organization)">
                            {{ organization.name }}
                        </nuxt-link>
                    </div>
                </div>
                <div class="item-description my-05">
                    {{ organization.short_description }}
                </div>

                <div class="company-status">
                    <span
                        v-tooltip
                        class="mr-1 text-muted"
                        :title="`${$t('organization.companyItem.stast.posts')} ${organization.posts_count}`"
                    >
                        <i class="fa fa-pencil" /> {{ organization.posts_count || 0 }}
                    </span>
                    <span
                        v-tooltip
                        class="mr-1 text-muted"
                        :title="`${$t('organization.companyItem.stast.followers')} ${organization.followers_count}`"
                    >
                        <i class="fa fa-user-plus" /> {{ organization.followers_count || 0 }}
                    </span>
                    <span
                        v-tooltip
                        class="mr-1 text-muted"
                        :title="`${$t('organization.companyItem.stast.member')} ${organization.members_count}`"
                    >
                        <i class="fa fa-users" /> {{ organization.members_count || 0 }}
                    </span>
                    <span
                        v-tooltip
                        class="mr-1 text-muted"
                        :title="`${$t('profile.organizations.view')} ${organization.postviews_count}`"
                    >
                        <i class="fa fa-eye" /> {{ organization.postviews_count | formatNumber }}
                    </span>
                    <span
                        v-if="organization.website"
                        v-tooltip
                        class="mr-1 text-muted"
                        title="URL"
                    >
                        <i class="fa fa-globe" />&nbsp;
                        <a
                            :href="organization.website"
                            target="_blank"
                            rel="noopener"
                        >{{ organization.website || '' }}</a>
                    </span>
                    <span
                        v-tooltip
                        class="mr-1 text-muted"
                        :title="`${$t('organization.companyItem.stast.location')}`"
                    >
                        <i class="fa fa-map-marker" /> {{ organization.location }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { organization as toOrganization } from '~/utils/url'
    import Avatar from '../shared/Avatar/Avatar.vue'

    export default {
        components: {
            Avatar,
        },

        props: {
            organization: Object,
        },

        methods: {
            toOrganization,
        },
    }
</script>

<style>
    .company-item-name {
        font-size: 18px
    }

    .company-show {
        border-bottom: 1px solid #D6D6D7;
    }

    .avatar-div {
        width: 95px;
        height: 95px;
        z-index: 1;
    }
    .margin-content{
        margin-right: 1.5rem;
    }
</style>
