// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="search-item d-flex">
        <Avatar
            :object="user"
            :image="user.avatar"
            :is-user="true"
            :level-partner="user.level_partner"
            :partner-width="45"
            :partner-hight="45"
            size="md"
            class="mr-05"
        />

        <div class="search-item__info w-100">
            <div class="search-item__meta">
                <UserInline
                    :user="user"
                    :show-avatar="false"
                    :show-username="false"
                />

                <span class="text-muted">{{ publishedTime | humanize-time }}</span>
            </div>

            <div class="search-item__title d-flex align-items-center flex-wrap">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <nuxt-link :to="url" class="link mr-05">
                    <Markdown :contents="titleMarkup" />
                </nuxt-link>

                <Tags :tags="tags" class="d-flex align-items-center flex-wrap" />
            </div>

            <div class="search-item__preview mb-05">
                <div v-if="highlights" class="search-item__matches text-muted">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <Markdown v-if="hasHighlight('contents')" :contents="getHighlight('contents')" />
                    <div v-text="preview" />

                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <code v-if="hasHighlight('code')">
                        <Markdown :contents="getHighlight('code')" />
                    </code>
                </div>

                <MarkdownPure v-else :contents="preview" />
            </div>

            <slot name="stats" />
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _some from 'lodash/some'
    import _escape from 'lodash/escape'
    import _isEmpty from 'lodash/isEmpty'
    import _isArray from 'lodash/isArray'

    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import Tags from '~/components/posts/widgets/Tags.vue'
    import UserInline from '~/components/shared/user/Inline.vue'
    import Markdown from '~/components/contents/Markdown.vue'

    export default {
        components: {
            Tags,
            Avatar,
            UserInline,
            Markdown,
        },

        props: {
            title: String,
            url: String,
            user: Object,
            tags: Array,
            highlights: Object,
            preview: String,
            publishedTime: [String, Number],
        },

        computed: {
            titleMarkup() {
                return this.hasHighlight('title') ? this.getHighlight('title') : _escape(this.title)
            },
        },

        methods: {
            hasHighlight(key) {
                const highlights = this.highlights

                return key
                    ? highlights && !_isEmpty(highlights[key])
                    : highlights && _some(highlights, Boolean)
            },

            getHighlight(key) {
                const highlight = _get(this.highlights, key)

                return _isArray(highlight) ? highlight[0] : highlight
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .search-item {
        &__preview {
            word-wrap: break-word;

            img {
                display: block;
                margin: 1em 0;
                max-width: 100%;

                &:not(:first-of-type) {
                    display: none;
                }
            }
        }

        &__meta {
            font-size: .75rem;
        }

        &__title {
            font-size: 1.1rem;
            margin-bottom: .25rem;
            .md-contents {
                font-size: 1.1rem !important;
            }
        }

        &__matches, &__title {
            b {
                color: $primary;
            }
            .md-contents {
                font-family: 'Roboto' !important;
            }
        }

        &__matches {
            .md-contents {
                font-size: 14px !important;
            }
        }
    }
</style>
