// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { mapState, mapGetters } from 'vuex'
import { updateVisitTime } from '~/api/stats'
import badgesMixin from './badges'

export default function createMixin(page, options = {}) {
    const {
        sendVisitAfter = 1000,
        visitFeed = 'newest',
        badges = true,
    } = options

    return {
        mixins: badges ? [badgesMixin()] : [],

        computed: {
            ...mapGetters('auth', ['authenticated']),
            ...mapState({
                lastSeen(state, getters) {
                    return getters['auth/lastSeen'](visitFeed)
                },
            }),
        },

        mounted() {
            if (this.authenticated) {
                this.$_sendVisitTimer = setTimeout(updateVisitTime.bind(null, page), sendVisitAfter)
            }
        },

        destroyed() {
            if (this.$_sendVisitTimer) {
                clearTimeout(this.$_sendVisitTimer)
            }
        },
    }
}
