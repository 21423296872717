// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import frontPage from '~/pages/__mixins/frontPage'
    import Feed from '~/pages/questions/_feed.vue'

    export default {
        mixins: [
            frontPage('questions-newest', { visitFeed: 'questions_newest', badges: false }),
            Feed,
        ],

        methods: {
            shouldHighlight({ created_at: createdAt, user_id: userId }) {
                if (!this.lastSeen || this.$store.getters['auth/isUser'](userId) || !createdAt) return false
                return new Date(this.lastSeen) < new Date(createdAt)
            },
        },
    }
</script>
