// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="col-sm-6 col-md-4 avatar-min-md">
        <user :user="user" :follow="follow" />
    </div>
</template>


<script>
    import { mapActions } from 'vuex'
    import User from '~/components/users/ProfileCard.vue'

    export default {
        components: {
            User,
        },
        props: {
            item: {
                type: Object,
            },
        },

        computed: {
            user() {
                return this.$store.getters['entities/users/get'](this.item.id)
            },
        },

        methods: {
            ...mapActions('entities/users', ['follow']),
        },
    }
</script>
