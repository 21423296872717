// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <PostFeedPlaceholder v-if="$fetchState.pending && isEmpty" />
    <PostFeed
        v-else
        :should-highlight="isNewPost"
        store="postHomeFeed"
    />
</template>

<script>
    import { mapGetters } from 'vuex'
    import { feedSEO } from '~/utils/seo'
    import frontPage from '~/pages/__mixins/frontPage'
    import dynamicFetching from '~/pages/__mixins/dynamicFetching'

    import PostFeed from '~/containers/posts/PostFeed.vue'
    import PostFeedPlaceholder from '~/containers/posts/PostFeedPlaceholder.vue'

    export default {
        name: 'TrendingPosts',

        components: {
            PostFeed,
            PostFeedPlaceholder,
        },

        mixins: [
            frontPage('trending', { visitFeed: 'trending' }),
            dynamicFetching(),
        ],

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapGetters('auth', ['isUser']),
            ...mapGetters('postHomeFeed', ['isEmpty']),
        },

        fetch() {
            return this.withProgressBar(() => this.$store.dispatch('postHomeFeed/fetch', {
                feed: 'trending',
                params: {
                    ...this.$route.query,
                    limit: this.$store.state.settings.perPage,
                },
            }))
        },

        methods: {
            isNewPost({ trend_at: trendAt, user_id: userId }) {
                if (!this.lastSeen || this.isUser(userId) || !trendAt) return false
                return new Date(this.lastSeen) < new Date(trendAt)
            },
        },

        watchQuery: ['page'],

        head() {
            const seo = feedSEO('trending')

            return {
                ...seo,
                title: this.withUnreadCount(seo.title),
            }
        },
    }
</script>
