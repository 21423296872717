// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <MainBanner />

        <div class="bg-dark py-md-1 py-05">
            <div class="container">
                <div class="row d-flex align-items-center justify-content-between">
                    <feed-bar :feeds="feeds" />

                    <nuxt-link
                        v-if="authenticated"
                        to="/questions/ask"
                        class="btn btn-primary float-right text-uppercase hidden-md-down"
                    >
                        <i class="fa fa-question-circle mr-05" /> {{ $t('question.askQuestion') }}
                    </nuxt-link>
                </div>
            </div>
        </div>

        <MessageOfTheDay />

        <NotificationBanner />

        <div class="container my-md-3">
            <div class="row">
                <div class="col-lg-9 pl-md-0">
                    <question-feed
                        :key="$route.fullPath"
                        :should-highlight="hasOwnProperty('shouldHighlight') ? shouldHighlight : undefined"
                        feed-store="questionHomeFeed"
                        @request-reload="reload"
                    />
                </div>

                <div class="col-lg-3 hidden-md-down px-0">
                    <StickySidebar :offset-bottom="59">
                        <Sidebar />
                    </StickySidebar>
                </div>
            </div>
        </div>

        <NoAvatarReminder />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { feedSEO } from '~/utils/seo'
    import { handlePageError } from '~/utils/pages'
    import feeds from '~/components/FeedBar/links/questions'
    import badgesMixin from '~/pages/__mixins/badges'

    import FeedBar from '~/components/FeedBar/index.vue'
    import MainBanner from '~/components/frontPage/MainBanner.vue'
    import StickySidebar from '~/components/StickySidebar.vue'
    import QuestionFeed from '~/components/feeds/QuestionFeed.vue'
    import Sidebar from '~/containers/questionHomeFeed/SidebarRecommend.vue'
    import NotificationBanner from '~/components/notifications/Banner.vue'
    import MessageOfTheDay from '~/components/frontPage/MessageOfTheDay.vue'
    import NoAvatarReminder from '~/components/dialogs/NoAvatarReminder.vue'

    const feedMap = {
        newest: 'questions-newest',
        unsolved: 'questions-unsolved',
        followings: 'questions-followings',
        clips: 'questions-clips',
    }

    export default {
        components: {
            MainBanner,
            FeedBar,
            StickySidebar,
            Sidebar,
            QuestionFeed,
            NotificationBanner,
            MessageOfTheDay,
            NoAvatarReminder,
        },

        mixins: [badgesMixin()],

        head() {
            const feedRoute = feedMap[this.$route.params.feed || 'newest']
            const seo = feedSEO(feedRoute)

            return {
                ...seo,
                title: this.withUnreadCount(seo.title),
            }
        },

        data: () => ({
            feeds,
            centerDialogVisible: true,
        }),

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapGetters('auth', ['authenticated']),
        },

        async fetch({
            store, query, params, error,
        }) {
            await Promise.all([
                store.dispatch('questionHomeFeed/fetchSidebar'),
                store.dispatch('questionHomeFeed/fetch', {
                    feed: params.feed || '',
                    params: {
                        limit: store.state.settings.perPage,
                        page: query.page,
                    },
                }),
                store.dispatch('config/changeBanner'),
                store.dispatch('config/changeMotd'),
                store.dispatch('config/changeLogo'),
            ])
                .catch(handlePageError(error))
        },

        methods: {
            reload() {
                this.$store.dispatch('questionHomeFeed/fetch', {
                    feed: this.$route.params.feed || '',
                    params: {
                        limit: this.$store.state.settings.perPage,
                        page: this.$route.query.page,
                    },
                })
            },
        },

        watchQuery: ['page'],
    }
</script>
