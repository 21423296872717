<template>
    <div class="suggestion-header d-flex mb-1">
        <a
            :href="getUrlService(icon)"
            class="title-suggestions text-uppercase"
            :title="title"
            target="_blank"
        >
            {{ title }}
        </a>
        <hr class="section-title__filler">
        <img
            v-if="icon === `interview`"
            src="/images/viblo-interview.ico"
            alt="Viblo Interview"
            class="link-icon"
            width="24"
            height="24"
        >
        <img
            v-if="icon === `code`"
            src="/images/viblo-code.png"
            alt="Viblo Code"
            class="link-icon"
            width="24"
            height="24"
        >
        <img
            v-if="icon === `ctf`"
            src="/images/viblo-ctf.png"
            alt="Viblo Code"
            class="link-icon"
            width="24"
            height="24"
        >
        <img
            v-if="icon === `learn`"
            src="/images/viblo-learn.png"
            alt="Viblo Code"
            class="link-icon"
            width="24"
            height="24"
        >
    </div>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
            icon: {
                type: String,
                required: true,
            },
            medium: {
                type: String,
                required: true,
            },
        },

        methods: {
            getUrlService(service) {
                switch (service) {
                    case 'interview':
                        return `${process.env.INTERVIEW_URL}/newest?utm_source=Viblo_Service&utm_medium=${this.medium}&utm_campaign=SuggestionServices`
                    case 'code':
                        return `${process.env.CODE_URL}/challenges?utm_source=Viblo_Service&utm_medium=${this.medium}&utm_campaign=SuggestionServices`
                    case 'learn':
                        return `${process.env.LEARN_URL}/courses?utm_source=Viblo_Service&utm_medium=${this.medium}&utm_campaign=SuggestionServices`
                    default:
                        return `${process.env.CTF_URL}/puzzles?utm_source=Viblo_Service&utm_medium=${this.medium}&utm_campaign=SuggestionServices`
                }
            },
        },
    }
</script>
<style lang="css" scoped>
    .suggestion-header {
        font-size: large;
    }
    .title-suggestions {
        font-size: 1rem;
        color: #5488c7;
    }
    .section-title__filler {
        margin-left: 0;
    }
</style>
