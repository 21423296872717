// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div v-if="unread" class="banner bg-dark dashed">
        <a href="/notifications" class="text-primary">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span class="banner-message" v-html="message" />
        </a>
    </div>
</template>

<style lang="scss" scoped>
    .banner {
        display: flex;
        padding: 1rem;
        align-items: center;
        justify-content: center;
    }

    .banner.dashed {
        border-top: 1px dashed rgba(255, 255, 255, 0.2);
    }

    .banner-message {
        font-size: .9rem;
        font-weight: bold;
    }

    .banner-action {
        color: #FFF !important;
    }
</style>

<script>
    import { mapState } from 'vuex'

    export default {
        computed: {
            ...mapState('notifications', ['unread']),

            message() {
                const unread = this.unread
                const pronoun = unread > 1 ? 'them' : 'it'
                const noun = unread > 1 ? 'notifications' : 'notification'

                return `You have <span class="text-primary">${unread}</span> unread ${noun}. Check ${pronoun} out!`
            },
        },
    }
</script>
