// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <MainBanner />
        <div id="comment-section" class="container post-section mb-2">
            <h3 class="post-section-title cross-line my-2">
                <strong>{{ $t('tagRecommend.title') }}</strong>
            </h3>

            <div v-if="tags.length === 0" class="text-center font-weight-bold text-muted my-05">
                <slot>{{ $t('tagRecommend.content') }}</slot>
            </div>

            <div v-else>
                <div class="row block-exhibition">
                    <div v-for="(tag, index) in tags" :key="index" class="col-sm-6 col-lg-4 my-2 exhibition-item">
                        <tag-item :tag="tag" />
                    </div>
                </div>

                <pagination
                    :current-page="pagination.current_page"
                    :last-page="pagination.total_pages"
                    :path="path"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { handlePageError } from '~/utils/pages'
    import { getRelatedTags } from '~/api/placements'
    import { normalizeTagList } from '~/components/entities/normalize'

    import MainBanner from '~/components/frontPage/MainBanner.vue'
    import TagItem from '~/components/tags/Card.vue'
    import Pagination from '~/components/Pagination.vue'

    export default {
        components: {
            MainBanner,
            TagItem,
            Pagination,
        },

        middleware: ['auth'],

        data() {
            return {
                pagination: {
                    current_page: 1,
                    total_pages: 0,
                },
            }
        },

        computed: {
            ...mapGetters('auth', ['authenticated']),

            tags() {
                return this.$store.getters['entities/tags/mapId'](this.tagIds)
            },

            page() {
                return (typeof this.$route.query.page === 'undefined') ? 1 : this.$route.query.page
            },

            path() {
                return this.$route.path
            },
        },

        async asyncData({
            store, error, query,
        }) {
            try {
                const page = (typeof query.page === 'undefined') ? 1 : query.page

                const { relatedTags, pagination } = await getRelatedTags({ page, limit: 30 })
                    .then(response => ({
                        relatedTags: response.data.data,
                        pagination: response.data.meta.pagination,
                    }))

                const normalizedTags = normalizeTagList(relatedTags)

                const tagIds = normalizedTags.result

                store.commit('entities/tags/put', normalizedTags.entities.tags)

                return { tagIds, pagination }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        watchQuery: ['page'],
    }
</script>
