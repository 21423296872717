// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-container class="pt-3 pb-1 search-page">
        <v-row>
            <v-col :md="9">
                <SearchBox
                    :query="$route.query.q"
                    :sort="$route.query.s"
                    :order="$route.query.o"
                    :type="$route.query.type"
                    @search="search"
                />

                <template v-if="!fetching && $route.query.q">
                    <SearchResults
                        :results="results"
                        :pagination="pagination"
                        :picked="onSuggestionPicked"
                    />
                </template>

                <div v-if="fetching" class="text-center mt-05 mb-05">
                    <p class="font-weight-bold text-muted">
                        <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" /> {{ $t('search.searching') }}...
                    </p>
                </div>
            </v-col>

            <v-col :md="3" class="hidden-md-down">
                <SearchGuide />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import _omit from 'lodash/omit'
    import _assign from 'lodash/assign'
    import _isEmpty from 'lodash/isEmpty'
    import { mapGetters } from 'vuex'
    import { search } from '~/api/search'

    import SearchTracking from '~/lib/trackings/search'
    import SearchGuide from '~/components/searchPage/Guide.vue'
    import SearchBox from '~/components/searchPage/SearchBox.vue'
    import SearchResults from '~/components/searchPage/Results.vue'
    import { normalizeUserList } from '~/components/entities/normalize'
    import { trackUserAgentInfo } from '~/lib/trackings/user-agent'

    export default {
        head: {
            title: 'Search Viblo',
        },

        name: 'Search',

        components: {
            SearchBox,
            SearchGuide,
            SearchResults,
        },

        data: () => ({
            fetching: false,
        }),

        computed: {
            ...mapGetters('auth', ['userId']),
        },

        async asyncData({ query, store }) {
            const searchParams = _omit(query, 'type')

            const data = query.q
                ? await search(query.type || 'posts', searchParams)
                : null
            const results = data ? data.data : []
            const meta = data ? data.meta : {
                pagination: {
                    current_page: 1,
                    total_pages: 0,
                    total: 0,
                },
            }
            if (query.type === 'users') {
                const users = normalizeUserList(results)
                store.commit('entities/users/put', users.entities.users)
            }

            return {
                results,
                ...meta,
            }
        },

        watchQuery: ['type', 'q', 's', 'o', 'page'],

        async beforeRouteUpdate(to, from, next) {
            this.trackLog()
            next()
        },

        async mounted() {
            this.trackLog()
        },

        methods: {
            search(payload) {
                const query = payload.append
                    ? _assign({}, _omit(this.$route.query, 'page'), payload.query)
                    : payload.query

                this.fetching = Boolean(query.q)
                this.$router.push({ query })
            },

            async trackLog() {
                this.$_searchTracking = SearchTracking('search_page', this.userId)
                const visitor = await trackUserAgentInfo()
                const pagination = this.pagination
                const logInput = {
                    search: this.$route.query,
                    ...visitor,
                    total_search_results: pagination.total,
                }

                this.saveLog(logInput)
            },

            async saveLog(logInput) {
                if (!_isEmpty(logInput)) {
                    const { data } = await this.$_searchTracking.saveLog(logInput)
                    this.$store.commit('log/setSearchLog', data.data)
                }
            },

            onSuggestionPicked({ index, item }) {
                const input = {
                    clicked_item: {
                        type: item.type,
                        id: item.id,
                        position_search_results: this.trackingPositionResults(index),
                    },
                    total_search_results: this.pagination.total,
                }
                const log = this.$store.getters['log/getSearchLog']
                this.$_searchTracking.updateLog(log, input).catch(() => {})
            },

            trackingPositionResults(index) {
                const { count, current_page: currentPage } = this.pagination

                return (currentPage - 1) * count + index + 1
            },
        },
    }
</script>

<style lang="scss">
    .search-page {
        @media only screen and (min-width: 768px) and (max-width: 992px) {
            .col-md-9 {
               flex: 0 0 100% !important;
               max-width: 100% !important;
            }
        }
    }
</style>
