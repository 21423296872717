// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _concat from 'lodash/concat'
import _hasIn from 'lodash/hasIn'
import Vue from 'vue'
import { zonedTimeToUtc } from 'date-fns-tz'
import { absoluteUrl, routeToFeed } from '~/lib/functions'
import { baseUrl } from '~/utils/url'
import { imageMetaTags, descriptionMetaTags } from './function'
import {
    homePageTitle,
    defaultSEOImage,
    defaultKeywords,
    defaultDescription,
    defaultName,
    defaultSocialUrls,
} from './defaultValue'


const dateNowUTC = zonedTimeToUtc(new Date(), 'Asia/Ho_Chi_Minh')
const currentYear = dateNowUTC.getFullYear()

const postFeedMap = {
    'clip-posts': 'headSeo.clipPost',
    'clip-series': 'headSeo.clipSeries',
    newest: 'headSeo.newestDefault',
    series: 'headSeo.series',
    subscriptions: 'headSeo.followingDefault',
    'editors-choice': "Editor's Choice",
    trending: 'headSeo.trending',
    translation: 'headSeo.translation',
    announcements: 'headSeo.announcements',
    videos: 'Videos',
    'may-fest': `May Fest ${currentYear}`,
    'content-creator': 'headSeo.contentCreatorPost',
}

const questionFeedMap = {
    'questions-newest': 'headSeo.questionsNewest',
    'questions-unsolved': 'headSeo.questionsUnsolved',
    'questions-followings': 'headSeo.questionsFollowings',
    'questions-clips': 'headSeo.clipQuestion',
    'questions-ask': 'headSeo.questionsAsk',
}

const feedMap = {
    ...postFeedMap,
    ...questionFeedMap,
    discussion: 'headSeo.discussion',
}

/**
 * Route to Question Feed
 * eg 'questions-newest' => /questions/newest
 * @param {string} feed
 */
function routeToQuestionFeed(feed) {
    const feedRoute = feed.split('-')

    return feedRoute[1] === 'newest' ? '/questions' : `/questions/${feedRoute[1]}`
}

const structuredData = (feed) => {
    const url = _hasIn(questionFeedMap, feed) ? routeToQuestionFeed(feed) : routeToFeed(feed)

    return {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: feedMap[feed],
        url: absoluteUrl(url),
        description: defaultDescription,
        publisher: {
            '@type': 'Organization',
            name: defaultName,
            url: baseUrl,
            logo: {
                '@type': 'ImageObject',
                url: defaultSEOImage,
            },
            sameAs: defaultSocialUrls,
        },
    }
}

export const feedSEO = (feedType) => {
    const title = feedType === 'homepage'
        ? Vue.prototype.$_i18n.t(homePageTitle)
        : Vue.prototype.$_i18n.t(`${feedMap[feedType]}`).concat(` - ${defaultName}`)
    const image = defaultSEOImage
    const url = _hasIn(questionFeedMap, feedType)
        ? routeToQuestionFeed(feedType)
        : routeToFeed(feedType)

    const baseMeta = [
        { hid: 'description', name: 'description', content: defaultDescription },
        { hid: 'keywords', name: 'keywords', content: defaultKeywords },
        // Opengraph:
        { property: 'og:title', content: title },
        { property: 'og:description', content: defaultDescription },
        { property: 'og:url', content: absoluteUrl(url) },
        { property: 'og:site_name', content: defaultName },
        { property: 'og:image', content: image },
        // twitter
        { hid: 'twitter:title', name: 'twitter:title', content: title },
        {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: defaultDescription,
        },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
    ]

    return {
        title,
        link: [{ hid: 'canonical', rel: 'canonical', href: absoluteUrl(routeToFeed(feedType)) }],
        meta: _concat(baseMeta, imageMetaTags(image), descriptionMetaTags(defaultDescription)),
        script: [
            { type: 'application/ld+json', json: structuredData(feedType) },
        ],
    }
}
