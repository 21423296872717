// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="mt-1">
        <div class="header-question">
            <div
                v-on-clickaway="hideTooltip"
                class="left-box"
                :style="{
                    width: !question.level ? `100%` : `80%`
                }"
            >
                <a :href="getUrl(`${question.link}?`, medium)" target="_blank" class="suggestion-item-content">
                    {{ question.contents }}
                </a>
                <p class="time-published text-muted">
                    {{ question.published_at | humanize-time }}
                </p>
                <el-popover
                    v-if="question && question.tags.length > 0"
                    v-model="visible"
                    placement="top-start"
                    width="200"
                >
                    <div
                        class="d-flex flex-wrap"
                        @mouseover="showToolTip(`${question.hash_id}-questions`)"
                        @mouseleave="hideTooltip"
                    >
                        <a
                            v-for="tag in question.tags"
                            :key="tag.slug"
                            class="suggestion-item-tags"
                            :href="getUrl(`${url}/tags/${tag.slug}?`, medium)"
                            target="_blank"
                        >
                            {{ tag.name }}
                        </a>
                    </div>
                    <div slot="reference">
                        <div
                            :id="`${question.hash_id}-questions`"
                            class="truncate-container"
                            @mouseover="showToolTip(`${question.hash_id}-questions`)"
                            @mouseleave="hideTooltip"
                        >
                            <a
                                v-for="tag in question.tags"
                                :key="tag.slug"
                                class="suggestion-item-tags"
                                :href="getUrl(`${url}/tags/${tag.slug}?`, medium)"
                                target="_blank"
                            >
                                {{ tag.name }}
                            </a>
                        </div>
                    </div>
                </el-popover>
                <SuggestionProperty
                    :question="question"
                    :stats="interviewQuestionStart"
                />
            </div>
            <a
                :class="`question-level ${question.level}`"
                :href="getUrl(`${url}/newest?type=questions&level=${question.level}&`, medium)"
                target="_blank"
            >
                {{ question.level }}
            </a>
        </div>
    </div>
</template>

<script>
    import { mixin as clickaway } from '@tranxuanthang/vue-clickaway'
    import SuggestionProperty from './SuggestionProperty.vue'
    import visibleTag from '~/components/sidebar/Suggestion/visibleTag'

    export default {
        components: {
            SuggestionProperty,
        },

        mixins: [visibleTag, clickaway],

        props: {
            question: Object,
            medium: String,
        },

        computed: {
            interviewQuestionStart() {
                return [
                    {
                        name: this.$t('stats.suggestion.question.view').concat(`${this.question.view_count}`),
                        icon: 'question-view-count',
                        value: this.question.view_count,
                    },
                    {
                        name: this.$t('stats.suggestion.question.bookmark').concat(`${this.question.bookmark_count}`),
                        icon: 'question-bookmark-count',
                        value: this.question.bookmark_count,
                    },
                    {
                        name: this.$t('stats.suggestion.question.comment').concat(`${this.question.comment_count}`),
                        icon: 'question-comment-count',
                        value: this.question.comment_count,
                    },
                    {
                        name: this.$t('stats.suggestion.question.vote').concat(`${this.question.vote_count}`),
                        icon: 'question-upvote-count',
                        value: this.question.vote_count,
                    },
                ]
            },

            url() {
                return process.env.INTERVIEW_URL
            },
        },

        mounted() {
            window.addEventListener('scroll', this.handleScroll)
        },

        destroyed() {
            window.removeEventListener('scroll', this.handleScroll)
        },

        methods: {
            handleScroll() {
                this.hideTooltip()
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    .sidebar__feed-item {
        padding-bottom: .5rem;
        margin-bottom: .5rem;
        border-bottom: 1px solid $gray-200;

        &__info{
            text-align: center;
            display: inline-block;
            div{
                float: left;
            }
        }

        &:last-of-type{
            border-bottom:none;
        }

        &__subtitle{
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .header-question {
            display: flex;
            justify-content: space-between;

            .question-level {
                padding: 1px 10px;
                height: fit-content;
                border-radius: 5px;
                font-size: smaller;
                color: white !important;

                &:hover {
                    text-decoration: none;
                }
            }

            .left-box {
                display: flex;
                flex-direction: column;
            }
        }

        .Fresher {
            background: #e05858;

        }

        .Senior {
            background: #ef9834;
        }

        .Middle {
            background: #4069e5;
        }

        .Junior {
            background: #8353e2;
        }
    }
</style>
