<template>
    <div>
        <el-dropdown>
            <span class="el-dropdown-link">
                {{ $t('contentCreator.rank.grade') }} <b>{{ $t(grade.text) }}</b>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-for="item in levelList"
                    :key="item.param"
                    @click.native="search(item.param)"
                >
                    {{ $t(item.text) }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>


<script>
    import _filter from 'lodash/filter'
    import levelList from '~/components/searchPage/levelPartner'

    export default {

        data() {
            return {
                level: this.$route.query.level || '',
                levelList,
            }
        },

        computed: {
            grade() {
                const level = this.$route.query.level || ''
                return _filter(levelList, data => data.param === level)[0]
            },
        },

        watch: {
            $route() {
                this.level = this.$route.query.level || ''
            },
        },

        methods: {
            search(level) {
                this.$router.push(`/content-creator?level=${level}&page=1`)
            },

            labelItem(item) {
                return this.$t(item.text)
            },
        },
    }
</script>
