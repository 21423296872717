// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="mt-3">
        <h2 class="mb-2 font-weight-bold">
            {{ $t(titleRank) }}
        </h2>

        <div v-if="postOrgLevelDiamond && level==='diamond'">
            <empty v-if="postOrgLevelDiamond.length === 0">
                <p> {{ $t('empty') }} </p>
            </empty>
            <div v-else>
                <div v-if="!showMoreDiamond">
                    <div class="post-feed mt-2">
                        <FeedItem
                            v-for="(post) in postOrgLevelDiamond.slice(0, 3)"
                            :key="post.id"
                            :post="post"
                            :highlight="shouldHighlight(post)"
                        />
                    </div>
                    <p class="link button-show-more" @click="showFull('diamond')">
                        {{ $t('showMore') }}
                    </p>
                </div>
                <div v-else class="post-feed mt-2">
                    <FeedItem
                        v-for="(post) in postOrgLevelDiamond"
                        :key="post.id"
                        :post="post"
                        :highlight="shouldHighlight(post)"
                    />

                    <Pagination
                        v-if="pagination"
                        :current-page="pagination.current_page"
                        :last-page="pagination.last_page"
                    />
                </div>
            </div>
        </div>

        <div v-else-if="postOrgLevelGold && level==='gold'">
            <empty v-if="postOrgLevelGold.length === 0">
                <p> {{ $t('empty') }} </p>
            </empty>
            <div v-else>
                <div v-if="!showMoreGold">
                    <div class="post-feed mt-2">
                        <FeedItem
                            v-for="(post) in postOrgLevelGold.slice(0, 3)"
                            :key="post.id"
                            :post="post"
                            :highlight="shouldHighlight(post)"
                        />
                    </div>
                    <p class="link button-show-more" @click="showFull('gold')">
                        {{ $t('showMore') }}
                    </p>
                </div>
                <div v-else class="post-feed mt-2">
                    <FeedItem
                        v-for="(post) in postOrgLevelGold"
                        :key="post.id"
                        :post="post"
                        :highlight="shouldHighlight(post)"
                    />

                    <Pagination
                        v-if="pagination"
                        :current-page="pagination.current_page"
                        :last-page="pagination.last_page"
                    />
                </div>
            </div>
        </div>

        <div v-else-if="postOrgLevelSilver && level==='silver'">
            <empty v-if="postOrgLevelSilver.length === 0">
                <p> {{ $t('empty') }} </p>
            </empty>
            <div v-else>
                <div v-if="!showMoreSilver">
                    <div class="post-feed mt-2">
                        <FeedItem
                            v-for="(post) in postOrgLevelSilver.slice(0, 3)"
                            :key="post.slug"
                            :post="post"
                            :highlight="shouldHighlight(post)"
                        />
                    </div>
                    <p class="link button-show-more" @click="showFull('silver')">
                        {{ $t('showMore') }}
                    </p>
                </div>
                <div v-else class="post-feed mt-2">
                    <FeedItem
                        v-for="(post) in postOrgLevelSilver"
                        :key="post.slug"
                        :post="post"
                        :highlight="shouldHighlight(post)"
                    />

                    <Pagination
                        v-if="pagination"
                        :current-page="pagination.current_page"
                        :last-page="pagination.last_page"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import FeedItem from '~/containers/posts/PostFeedItem.vue'
    import Pagination from '~/components/Pagination.vue'
    import Empty from '~/components/shared/Empty.vue'

    export default {
        components: {
            FeedItem,
            Pagination,
            Empty,
        },

        props: {
            level: String,
            titleRank: String,
            showMoreDiamond: {
                type: Boolean,
                default: false,
            },
            showMoreGold: {
                type: Boolean,
                default: false,
            },
            showMoreSilver: {
                type: Boolean,
                default: false,
            },
            shouldHighlight: {
                type: Function,
                default: () => false,
            },
            postOrgLevelSilver: Array,
            postOrgLevelGold: Array,
            postOrgLevelDiamond: Array,
            pagination: Object,
            state: Boolean,
        },

        watch: {
            async $route() {
                const level = this.$route.query.level || ''
                const page = this.$route.query.page || 1

                await this.$store.dispatch('postHomeFeed/getPostInOrgByLevel', {
                    level, page,
                })
            },
        },

        watchQuery: true,

        methods: {
            showFull(level) {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        level,
                        page: 1,
                    }),
                })
                this.$emit('showFull', level)
            },
        },
    }
</script>

<style lang="scss">
    .button-show-more {
        color: #5488c7;
        margin-top: 1rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    .button-show-more:hover {
        text-decoration: underline;
    }

    .posts-diamond {
        justify-content: space-around;

        .item-post-partner {
            width: 30%;
        }
    }
</style>
