// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div
        v-if="message"
        :style="bgStyle"
        class="text-white text-center h3 font-weight-bold py-1"
    >
        <a
            v-if="message.link"
            :href="message.link"
            target="_blank"
            rel="noopener"
            class="text-white"
        >
            {{ message.text }}
        </a>

        <template v-else>
            {{ message.text }}
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        computed: {
            ...mapGetters('config', { message: 'currentMotd', bgColor: 'currentBg' }),

            bgStyle() {
                return `background: linear-gradient(to right, ${this.bgColor});`
            },
        },
    }
</script>
