// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _concat from 'lodash/concat'
import _isUndefined from 'lodash/isUndefined'
import _isEmpty from 'lodash/isEmpty'
import { absoluteUrl, routeToUser } from '~/lib/functions'
import { baseUrl, image as imageUrl } from '~/utils/url'
import {
    descriptionMetaTags,
    imageMetaTags,
    formatToDate,
    extractImageFromContent,
    shortenHeadline,
} from './function'
import {
    defaultName, defaultKeywords, defaultSEOImage, defaultSocialUrls,
} from './defaultValue'

const getPostInfo = (post) => {
    let title
    let description

    if (post.seo_setting) {
        const seoData = post.seo_setting.data

        title = seoData.title || post.title
        description = seoData.description || post.preview.teaser
    } else {
        title = post.title
        description = post.preview.teaser
    }

    return {
        title,
        description,
    }
}

const structuredData = (post) => {
    const hasOrganization = !(_isEmpty(post.organization) || _isUndefined(post.organization.data))
    const systemPost = _isEmpty(post.user)
    const organizationName = hasOrganization ? post.organization.data.name : defaultName
    const organizationLogo = hasOrganization
        ? imageUrl(post.organization.data.avatar)
        : defaultSEOImage

    const defaultOrganizationType = {
        '@type': 'Organization',
        name: organizationName,
        logo: {
            '@type': 'ImageObject',
            url: organizationLogo,
        },
        sameAs: hasOrganization ? null : defaultSocialUrls,
    }

    const { title, description } = getPostInfo(post)

    return {
        '@context': 'https://schema.org',
        '@type': 'Article',
        image: extractImageFromContent(post.contents),
        author: systemPost
            ? defaultOrganizationType
            : {
                '@type': 'Person',
                name: post.user.data.name,
                '@id': absoluteUrl(routeToUser(post.user.data)),
                url: absoluteUrl(routeToUser(post.user.data)),
            },
        keywords: _isEmpty(post.tags)
            ? defaultKeywords
            : post.tags.data.map(tag => tag.name).join(', '),
        headline: shortenHeadline(title),
        url: post.canonical_url,
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `${baseUrl}/newest`,
        },
        publisher: defaultOrganizationType,
        datePublished: formatToDate(post.published_at),
        dateCreated: formatToDate(post.created_at),
        dateModified: formatToDate(post.updated_at),
        description,
        articleBody: description,
    }
}

const postSEO = (post) => {
    const keywords = _isEmpty(post.tags)
        ? defaultKeywords
        : post.tags.data.map(tag => tag.name).join(', ')
    const image = post.preview.image

    const { title, description } = getPostInfo(post)

    const baseMeta = [
        { hid: 'og:type', property: 'og:type', content: 'article' },
        { hid: 'og:url', property: 'og:url', content: post.canonical_url },
        { hid: 'og:site_name', property: 'og:site_name', content: defaultName },
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'keywords', name: 'keywords', content: keywords },
        {
            hid: 'article:published_time',
            name: 'article:published_time',
            content: post.published_at,
        },
        {
            hid: 'article:modified_time',
            name: 'article:modified_time',
            content: post.updated_at,
        },
        {
            hid: 'article:author',
            name: 'article:author',
            content: _isEmpty(post.user) ? defaultName : absoluteUrl(routeToUser(post.user.data)),
        },
        { hid: 'article:tag', name: 'article:tag', content: keywords },
        // twitter
        { hid: 'twitter:title', name: 'twitter:title', content: title },
        {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: description,
        },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
        {
            hid: 'robots',
            name: 'robots',
            content: post.moderation === 'pending' ? 'noindex,nofollow' : 'index,follow',
        },
    ]

    return {
        title,
        link: [{ hid: 'canonical', rel: 'canonical', href: post.canonical_url }],
        meta: _concat(baseMeta, imageMetaTags(image), descriptionMetaTags(description)),
        script: [{ type: 'application/ld+json', json: structuredData(post) }],
    }
}

export { postSEO }
