// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <PostsByLevel
            v-if="!$fetchState.pending && !isNull"
            title-rank="contentCreator.rank.silverGrade"
            level="silver"
            :should-highlight="isNewPost"
            :show-more-silver="showMoreSilver"
            :post-org-level-silver="postOrgLevelSilver"
            :pagination="pagination"
            @showFull="showFull"
        />
        <PostPlaceholder v-else />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import frontPage from '~/pages/__mixins/frontPage'
    import PostPlaceholder from '~/components/PostByLevelPlaceholder.vue'
    import PostsByLevel from '~/components/organizations/PostsByLevel.vue'

    export default {

        components: {
            PostPlaceholder,
            PostsByLevel,
        },

        mixins: [
            frontPage('content-creator', { visitFeed: 'content_creator' }),
        ],

        props: {
            showMoreSilver: {
                type: Boolean,
                default: false,
            },
            showPostByLevel: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState('postHomeFeed', ['postOrgLevelSilver', 'pagination']),

            isNull() {
                return this.postOrgLevelGold === null
            },
        },

        async fetch() {
            if (this.$nuxt.$loading.start) {
                this.$nuxt.$loading.start().increase(5)
            }
            const page = this.$route.query.page || 1

            await this.$store.dispatch('postHomeFeed/getPostInOrgByLevel', { level: 'silver', page })
        },

        methods: {
            showFull(level) {
                this.$emit('showFullByLevel', level)
            },

            isNewPost({ published_at: publishedAt, user_id: userId }) {
                if (!this.lastSeen || this.$store.getters['auth/isUser'](userId) || !publishedAt) return false
                return new Date(this.lastSeen) < new Date(publishedAt)
            },
        },
    }
</script>
