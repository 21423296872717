// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <Empty v-if="!$fetchState.pending && !isNull && seriesHome.length === 0">
        <p> {{ $t('empty') }} </p>
    </Empty>

    <div v-else-if="!isNull && seriesHome.length > 0">
        <div class="text-muted text-center mb-1 d-flex flex-column">
            {{ $t('titleSeries') }}
            <nuxt-link
                v-if="!guest"
                to="/publish/series"
                class="btn btn-primary ml-auto mt-1 text-uppercase"
            >
                <i class="fa fa-plus" aria-hidden="true" /> {{ $t('series.createButton') }}
            </nuxt-link>
        </div>
        <SeriesList :data="seriesHome" :should-highlight="isNewSeries" />

        <pagination
            v-if="paginationSeries"
            :current-page="paginationSeries.current_page"
            :last-page="paginationSeries.total_pages"
        />
    </div>

    <PostFeedPlaceholder v-else />
</template>

<script>
    import { mapGetters, mapState } from 'vuex'

    import { feedSEO } from '~/utils/seo'
    import frontPage from '~/pages/__mixins/frontPage'
    import dynamicFetching from '~/pages/__mixins/dynamicFetching'

    import Empty from '~/components/shared/Empty.vue'
    import Pagination from '~/components/Pagination.vue'
    import SeriesList from '~/components/series/List.vue'
    import PostFeedPlaceholder from '~/containers/posts/PostFeedPlaceholder.vue'

    export default {
        name: 'SeriesListPage',

        components: {
            Empty,
            Pagination,
            SeriesList,
            PostFeedPlaceholder,
        },

        mixins: [
            frontPage('series', { visitFeed: 'series' }),
            dynamicFetching(),
        ],

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapGetters('auth', ['isUser']),
            ...mapState('seriesView', ['seriesHome', 'paginationSeries']),

            isNull() {
                return this.seriesHome === null
            },
        },

        fetch() {
            return this.withProgressBar(async () => {
                this.$store.dispatch('seriesView/getSeriesHomeFeed', {
                    params: {
                        ...this.$route.query,
                        limit: this.$store.state.settings.perPage,
                    },
                })
            })
        },

        created() {
            if (!this.$store.getters['auth/authenticated']) {
                this.$store.dispatch('seriesView/getSeriesHomeFeed', {
                    params: {
                        ...this.$route.query,
                        limit: this.$store.state.settings.perPage,
                    },
                })
            }
        },

        methods: {
            isNewSeries({ published_at: publishedAt, user_id: userId }) {
                if (!this.lastSeen || this.isUser(userId) || !publishedAt) return false
                return new Date(this.lastSeen) < new Date(publishedAt)
            },
        },

        head() {
            const seo = feedSEO('series')

            return {
                ...seo,
                title: this.withUnreadCount(seo.title),
            }
        },

        watchQuery: ['page'],
    }
</script>
