// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <Item
        :title="item.title"
        :url="url"
        :user="item.user.data"
        :tags="item.tags.data"
        :highlights="item.highlights"
        :preview="item.contents_short"
        :published-time="item.created_at"
        class="col-md-12"
    >
        <div slot="stats" class="d-flex justify-content-between">
            <Stats :stats="item.stats" />
            <Score :score="item.points" :rated="item.rated_value" />
        </div>
    </Item>
</template>

<script>
    import { routeToQuestion } from '~/lib/functions'

    import Score from '~/components/widgets/Score.vue'
    import Stats from '~/components/posts/widgets/Stats.vue'
    import Item from './Item.vue'

    export default {
        components: {
            Item,
            Score,
            Stats,
        },

        props: {
            item: {
                type: Object,
                required: true,
            },
        },

        computed: {
            url() {
                return routeToQuestion(this.item)
            },
        },
    }
</script>
