// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <PostFeedPlaceholder v-if="$fetchState.pending && isEmpty" />
    <PostFeed
        v-else
        :should-highlight="isNewPost"
        :pinned-item="pinnedItem"
        store="postHomeFeed"
    />
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import frontPage from '~/pages/__mixins/frontPage'
    import dynamicFetching from '~/pages/__mixins/dynamicFetching'

    import PostFeed from '~/containers/posts/PostFeed.vue'
    import PostFeedPlaceholder from '~/containers/posts/PostFeedPlaceholder.vue'
    import { feedSEO } from '~/utils/seo'

    export default {
        name: 'FollowingPosts',

        components: {
            PostFeed,
            PostFeedPlaceholder,
        },

        mixins: [
            frontPage('subscriptions', { visitFeed: 'subscriptions' }),
            dynamicFetching(),
        ],

        middleware: ['auth'],

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapGetters('auth', ['isUser']),
            ...mapGetters('postHomeFeed', ['isEmpty']),
            ...mapState('announcements', ['pinnedItem']),
        },

        head() {
            const seo = feedSEO('subscriptions')
            let title = this.$t('headSeo.following')
            title = this.$store.state.settings.homepage === 'newest' ? title : seo.title

            return {
                ...seo,
                title: this.withUnreadCount(title),
            }
        },

        fetch() {
            return this.withProgressBar(() => {
                if (!this.pinnedItem.length > 0) {
                    this.$store.dispatch('announcements/getPinnedItem')
                }

                return Promise.all([
                    this.authenticated ? this.$store.dispatch('settings/getSettingUser') : null,
                    this.$store.dispatch('postHomeFeed/fetch', {
                        feed: 'subscriptions',
                        params: {
                            ...this.$route.query,
                            limit: this.$store.state.settings.perPage,
                        },
                    }),
                ])
            })
        },

        methods: {
            isNewPost({ published_at: publishedAt, user_id: userId }) {
                if (!this.lastSeen || this.isUser(userId) || !publishedAt) return false
                return new Date(this.lastSeen) < new Date(publishedAt)
            },
        },

        watchQuery: ['page'],
    }
</script>
