// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="col-md-12">
        <Item
            :title="item.title"
            :url="url"
            :user="item.user.data"
            :tags="item.tags.data"
            :highlights="item.highlights"
            :preview="item.teaser"
            :published-time="item.published_at"
        >
            <div v-if="!item.system" slot="stats" class="d-flex justify-content-between">
                <Stats :stats="item.stats" />
                <Score :score="item.points" :rated="item.rated_value" />
            </div>
        </Item>
    </div>
</template>

<script>
    import { routeToPost } from '~/lib/functions'

    import Score from '~/components/widgets/Score.vue'
    import Stats from '~/components/posts/widgets/Stats.vue'
    import Item from './Item.vue'

    export default {
        components: {
            Item,
            Score,
            Stats,
        },

        props: {
            item: {
                type: Object,
                required: true,
            },
        },

        computed: {
            url() {
                return routeToPost(this.item)
            },
        },
    }
</script>
