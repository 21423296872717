// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="results">
        <div v-if="noResult" class="text-center mt-05 mb-05">
            <p class="font-weight-bold">
                {{ $t('search.not_found') }}
            </p>
        </div>

        <template v-else>
            <div class="text-right">
                <b>{{ pagination.total | formatNumber(4) }}</b> {{ $t('search.results' ) }}
            </div>

            <div class="row">
                <template
                    v-for="(item, index) in results"
                >
                    <component
                        :is="item.type"
                        :key="index"
                        :item="item"
                        @click.native="() => clickItem(index, item)"
                    />
                    <hr v-if="item.type !== 'User'" :key="`hr-${index}`" class="col-md-12">
                </template>
            </div>

            <pagination
                :current-page="pagination.current_page"
                :last-page="pagination.total_pages <= 30 ? pagination.total_pages : 30"
            />
        </template>
    </div>
</template>

<script>
    import Pagination from '~/components/Pagination.vue'
    import Post from './items/Post.vue'
    import Question from './items/Question.vue'
    import User from './items/User.vue'

    export default {
        components: {
            Post,
            Question,
            Pagination,
            User,
        },

        props: {
            results: {
                type: Array,
                default: () => [],
            },
            pagination: {
                type: Object,
                default: () => ({
                    current_page: 1,
                    total_pages: 0,
                    total: 0,
                }),
            },
            picked: {
                type: Function,
            },
        },

        computed: {
            noResult() {
                return this.results && this.results.length === 0
            },
        },

        methods: {
            clickItem(index, item) {
                // to-do: $emit sometimes not working
                this.picked({ index, item })
            },
        },
    }
</script>

<style>
    .results {
        overflow-x: hidden !important;
    }
</style>
