export default {
    data() {
        return {
            visible: false,
        }
    },

    methods: {
        getUrl(url, medium) {
            return `${url}utm_source=Viblo_Service&utm_medium=${medium}&utm_campaign=SuggestionServices`
        },

        showToolTip(id) {
            const widthElement = document.getElementById(id).offsetWidth
            this.visible = widthElement >= 250
        },

        hideTooltip() {
            this.visible = false
        },
    },
}
