// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="main-feed__sidebar">
        <div v-if="showPosts" class="main-feed__sidebar__posts-feed">
            <SectionTitle :title="$t('question.newestPost')" link="/newest" />
            <FeedItem
                v-for="post in sidebar.posts"
                :key="post.hash_id"
                :title="post.title"
                :path="toPost(post)"
                :user="post.user.data"
            >
                <Stats :stats="postStats(post)" class="text-nowrap" />
                <Score :score="post.points" :title="$t('question.score')" class="text-nowrap" />
            </FeedItem>
        </div>
        <div
            v-if="!$fetchState.pending"
            class="mt-2"
        >
            <div
                v-if="showInterviewQuestions"
                class="pr-1"
            >
                <SuggestionTilte
                    :title="$t('sidebar.suggestion.question.title')"
                    :icon="`interview`"
                    medium="QuestionPage Viblo"
                />
                <SuggestionList>
                    <QuestionItem
                        v-for="question in interviewQuestions"
                        :key="question.hash_id"
                        :question="question"
                        medium="QuestionPage Viblo"
                    />
                </SuggestionList>
            </div>
        </div>
        <SuggestionSkeleton v-else />

        <div v-if="showTopAuthors" class="main-feed__sidebar__top-authors mt-1 mb-4">
            <SectionTitle :title="$t('question.topAuthors')" link="/authors" />

            <UserList store="questionHomeFeed/sidebar/users">
                <UserCard
                    slot-scope="item"
                    :user="item.user"
                    :follow="item.userAction('follow')"
                    :stats="['reputation', 'followers', 'questions', 'answers']"
                />
            </UserList>
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { mapState, mapActions, mapGetters } from 'vuex'
    import { subscribe } from 'viblo-sdk/api/me'
    import { SubscribableType } from 'viblo-sdk/libs/interactions'
    import _cloneDeep from 'lodash/cloneDeep'
    import { post as toPost } from '~/utils/url'
    import { getUserPartners, getOrganizationPartners } from '~/api/placements'
    import UserList from '~/containers/user/UserList.vue'
    import FeedItem from '~/components/sidebar/FeedItem.vue'
    import UserCard from '~/components/sidebar/UserSidebarCard.vue'
    import Stats from '~/components/shared/Stats.vue'
    import SectionTitle from '~/components/shared/SectionTitle.vue'
    import Score from '~/components/widgets/Score.vue'
    import SuggestionList from '~/components/sidebar/Suggestion/SuggestionList.vue'
    import QuestionItem from '~/components/sidebar/Suggestion/InterviewQuestion/QuestionItem.vue'
    import SuggestionTilte from '~/components/sidebar/Suggestion/SuggestionTilte.vue'
    import SuggestionSkeleton from '~/components/sidebar/Suggestion/SuggestionSkeleton.vue'

    export default {
        components: {
            Stats,
            FeedItem,
            UserList,
            UserCard,
            SectionTitle,
            Score,
            SuggestionList,
            QuestionItem,
            SuggestionTilte,
            SuggestionSkeleton,
        },

        data() {
            return {
                dataUsers: [],
                dataOrgs: [],
                dataUsersAfterUpdate: [],
                dataOrgsAfterUpdate: [],
                swiperOption: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    slidesPerGroup: 1,
                    loopFillGroupWithBlank: true,
                    loop: true,
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                },
                swiper: null,
            }
        },

        computed: {
            ...mapState('questionHomeFeed', ['sidebar']),
            ...mapGetters('questionHomeFeed', ['getUserPartners']),

            showTopAuthors() {
                return _get(this.sidebar, 'users', []).length > 0
            },

            showPosts() {
                return _get(this.sidebar, 'posts', []).length > 0
            },

            showOrganizationPartners() {
                return _get(this.sidebar, 'organizationPartners', []).length > 0
            },

            showUserPartners() {
                return this.getUserPartners.length > 0
            },

            interviewQuestions() {
                return _get(this.sidebar, 'interviewQuestions', [])
            },

            showInterviewQuestions() {
                return _get(this.sidebar, 'interviewQuestions', []).length > 0
            },

        },
        watch: {
            dataUsers(value) {
                this.dataUsersAfterUpdate = value
            },

            dataOrgs(value) {
                this.dataOrgsAfterUpdate = value
            },
        },

        async fetch() {
            const [organizations, userPartners] = await Promise.all([
                getOrganizationPartners().then(_ => _.data.data),
                getUserPartners().then(_ => _.data.data),
            ])
            this.dataOrgs = organizations.map(userObj => ({ ...userObj, isOwner: this.$store.getters['auth/isUser'](userObj.user_id) }))
            this.dataUsers = userPartners.map(userObj => ({ ...userObj, is_self: this.$store.getters['auth/isUser'](userObj.id) }))
        },

        methods: {
            toPost,

            ...mapActions('entities/users', ['follow']),

            postStats(post) {
                return [
                    {
                        name: this.$t('question.views').concat(`${post.views_count}`),
                        icon: 'fa fa-eye',
                        value: post.views_count,
                    },
                    {
                        name: this.$t('question.comments').concat(`${post.comments_count}`),
                        icon: 'fa fa-comment',
                        value: post.comments_count,
                    },
                    {
                        name: this.$t('question.cliped').concat(`${post.clips_count}`),
                        icon: 'fa fa-bookmark',
                        value: post.clips_count,
                    },
                ]
            },

            ...mapActions('entities/users', ['follow']),

            async updateUserFollow(data) {
                await subscribe(SubscribableType.User, data.user.username, data.value)
                const foundIndex = this.dataUsers.findIndex(item => item.id === data.user.id)

                const dataUpdate = {
                    answers_count: data.user.answers_count,
                    avatar: data.user.avatar,
                    banned_at: data.user.banned_at,
                    level_partner: data.user.level_partner,
                    name: data.user.name,
                    posts_count: data.user.posts_count,
                    questions_count: data.user.questions_count,
                    username: data.user.username,
                    id: data.user.id,
                    following: data.value,
                    followers_count: data.user.followers_count + (data.value ? 1 : -1),
                    reputation: data.user.reputation + (data.value ? 7 : -7),
                }
                this.dataUsers[foundIndex] = dataUpdate
                this.dataUsersAfterUpdate = _cloneDeep(this.dataUsers)
            },

            updateOrgFollow(data) {
                const foundIndex = this.dataOrgs.findIndex(item => item.id === data.organization.id)
                const dataUpdate = {
                    approved: data.organization.approved,
                    followers_count: data.organization.followers_count + (data.value ? 1 : -1),
                    avatar: data.organization.avatar,
                    following: data.value,
                    id: data.organization.id,
                    level_partner: data.organization.level_partner,
                    members_count: data.organization.members_count,
                    name: data.organization.name,
                    posts_count: data.organization.posts_count,
                    postviews_count: data.organization.postviews_count,
                    slug: data.organization.slug,
                }
                this.dataOrgs[foundIndex] = dataUpdate
                this.dataOrgsAfterUpdate = _cloneDeep(this.dataOrgs)
            },
        },
    }
</script>
<style lang="css">
    .suggesstion-title {
        font-size: 1.3rem;
    }
</style>
