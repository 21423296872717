// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <MainBanner />
        <notification-banner />

        <div id="company-section" class="container post-section my-4">
            <div class="d-flex align-items-baseline align-items-xs-between flex-xs-column flex-sm-row mb-3 mt-05">
                <div class="section-title-line full-width">
                    <h3 class="text-uppercase">
                        <strong>{{ $t('organization.title') }}</strong>
                    </h3>
                    <hr class="mx-1 filler-line">
                </div>
                <div class="d-flex align-items-baseline align-items-xs-between flex-shrink-0">
                    <span class="flex-shrink-0 mr-1">{{ $t('organization.sort.label') }}</span>
                    <el-select
                        :value="sort"
                        class="form-control"
                        size="small"
                        @change="sortEvent"
                    >
                        <el-option
                            v-for="(option, index) in sort_options"
                            :key="index"
                            :label="$t(`organization.sort.${option}`)"
                            :value="option"
                        >
                            {{ $t(`organization.sort.${option}`) }}
                        </el-option>
                    </el-select>
                </div>
            </div>

            <Organization
                v-for="(organization, index) in organizations"
                :key="index"
                :organization="organization"
            />

            <pagination
                v-if="pagination"
                :current-page="pagination.current_page"
                :last-page="pagination.total_pages"
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { normalize } from 'normalizr'
    import { fetchAllOrganizations } from '~/api/organizations'
    import { sharing } from '~/utils/seo'
    import { handlePageError } from '~/utils/pages'

    import { organizationArray } from '../../store/__utils/schemas'

    import MainBanner from '~/components/frontPage/MainBanner.vue'
    import NotificationBanner from '~/components/notifications/Banner.vue'
    import Organization from '~/components/organizations/Item.vue'
    import Pagination from '~/components/Pagination.vue'

    const filterFromQuery = query => ({
        page: (typeof query.page === 'undefined') ? 1 : query.page,
        sort: (typeof query.sort === 'undefined') ? 'posts' : query.sort,
    })

    const title = 'Organizations - Viblo'
    const meta = sharing({ title })

    export default {
        components: {
            MainBanner,
            NotificationBanner,
            Pagination,
            Organization,
        },

        head() {
            return {
                title: this.withUnreadCount(title),
                meta,
            }
        },

        data() {
            return {
                sort_options: ['posts', 'users', 'followers'],

                pagination: {
                    current_page: 1,
                    total_pages: 0,
                },
            }
        },

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),

            organizations() {
                return this.$store.getters['entities/organizations/mapId'](this.companyIds)
            },

            sort() {
                return this.$route.query.sort || 'posts'
            },
        },

        async asyncData({ query, store, error }) {
            const filters = filterFromQuery(query)

            try {
                const response = await fetchAllOrganizations(filters)
                    .then(({ data }) => data)

                const organizations = response.data

                const normalizedCompanies = normalize(organizations, organizationArray)

                const companyIds = normalizedCompanies.result

                store.commit('entities/organizations/put', normalizedCompanies.entities.organizations)

                return { companyIds, pagination: response.meta.pagination }
            } catch (err) {
                return handlePageError(error, err)
            }
        },

        watchQuery: ['page', 'sort'],

        methods: {
            ...mapGetters('notifications', [
                'getUnreadString',
            ]),

            sortEvent(value) {
                this.$router.push({
                    path: '/organizations',
                    query: {
                        sort: value,
                    },
                })
            },
        },
    }
</script>
