// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="sidebar__feed-item">
        <nuxt-link :to="path" class="link word-break">
            <h4>{{ title }}</h4>
        </nuxt-link>

        <div class="sidebar__feed-item__info full-width">
            <slot />
        </div>

        <div class="sidebar__feed-item__subtitle">
            <nuxt-link :to="userPage" :class="{ 'is-banned' : user.banned_at}" class="link link--plain text-muted">
                {{ user.name }}
            </nuxt-link>
        </div>
    </div>
</template>

<script>
    import { user as toUser } from '~/utils/url'

    export default {
        props: {
            title: String,
            path: String,
            user: Object,
        },

        computed: {
            userPage() {
                return toUser(this.user)
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .sidebar__feed-item {
        padding-bottom: .5rem;
        margin-bottom: .5rem;
        border-bottom: 1px solid $gray-200;

        &__info{
            text-align: center;
            display: inline-block;
            div{
               float: left;
           }
        }

        &:last-of-type{
            border-bottom:none;
        }

        &__subtitle{
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

</style>
