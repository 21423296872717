// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _concat from 'lodash/concat'
import { absoluteUrl, routeToSeries } from '~/lib/functions'
import { baseUrl } from '~/utils/url'
import {
    imageMetaTags,
    descriptionMetaTags,
    formatToDate,
    extractImageFromContent,
    shortenHeadline,
} from './function'
import { defaultName, defaultSEOImage, defaultSocialUrls } from './defaultValue'

const structuredData = (series) => {
    const contents = series.seo.contents
    const organizationName = defaultName
    const organizationLogo = defaultSEOImage

    return {
        '@context': 'https://schema.org',
        '@type': 'Article',
        image: extractImageFromContent(series.contents),
        author: series.user.data.name,
        headline: shortenHeadline(series.title),
        url: absoluteUrl(routeToSeries(series)),
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `${baseUrl}/series`,
        },
        publisher: {
            '@type': 'Organization',
            name: organizationName,
            logo: {
                '@type': 'ImageObject',
                url: organizationLogo,
            },
            sameAs: defaultSocialUrls,
        },
        datePublished: formatToDate(series.published_at),
        dateCreated: formatToDate(series.created_at),
        dateModified: formatToDate(series.edited_at),
        description: contents,
    }
}

const seriesSEO = (series) => {
    const description = series.seo.description
    const image = series.seo.image

    const baseMeta = [
        { hid: 'description', name: 'description', content: series.seo.description },
        // Opengraph
        { hid: 'og:title', property: 'og:title', content: series.title },
        { hid: 'og:description', property: 'og:description', content: series.seo.description },
        { hid: 'og:type', property: 'og:type', content: 'article' },
        { hid: 'og:url', property: 'og:url', content: absoluteUrl(routeToSeries(series)) },
        { hid: 'og:site_name', property: 'og:site_name', content: defaultName },
        // Article attribute:
        {
            hid: 'article:published_time',
            name: 'article:published_time',
            content: series.seo.published_at,
        },
        {
            hid: 'article:modified_time',
            name: 'article:modified_time',
            content: series.seo.updated_at,
        },
        { hid: 'article:author', name: 'article:author', content: series.user.data.url },
        // twitter
        { hid: 'twitter:title', name: 'twitter:title', content: series.title },
        {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: series.seo.description,
        },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
    ]

    return {
        title: `${series.title} - Viblo`,
        link: [{ rel: 'canonical', href: absoluteUrl(routeToSeries(series)) }],
        meta: _concat(baseMeta, imageMetaTags(image), descriptionMetaTags(description)),
        script: [{ type: 'application/ld+json', json: structuredData(series) }],
    }
}

export { seriesSEO }
