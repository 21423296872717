<template>
    <section>
        <el-skeleton animated>
            <template slot="template">
                <div class="mb-2 mt-3 d-flex">
                    <el-skeleton-item variant="h2" style="width:120px" />
                    <el-skeleton-item
                        variant="image"
                        style="width:20px;height:20px;border-radius:30%"
                    />
                </div>
            </template>
        </el-skeleton>
        <el-skeleton animated :count="3">
            <template slot="template">
                <div class="d-flex mb-1">
                    <div class="flex-fill">
                        <el-skeleton-item variant="text" style="width:75%" />
                        <el-skeleton-item variant="h3" style="width:85%" />
                        <el-skeleton-item variant="text" style="width:80%" />
                    </div>
                    <div class="mr-05">
                        <el-skeleton-item
                            variant="image"
                            style="width:40px;height:40px"
                        />
                    </div>
                </div>
            </template>
        </el-skeleton>
    </section>
</template>
<script>
    export default {

    }
</script>
